import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DayBar from '~/client/src/shared/components/DayBar'
import DeliveryDetails from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import SitePermitCreationForm from '~/client/src/shared/components/SitePermitCreationForm/SitePermitCreationForm'
import SitePermitViewForm from '~/client/src/shared/components/SitePermitCreationForm/SitePermitViewForm'
import { UrlParamKey } from '~/client/src/shared/constants/commonRoutes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Activity from '~/client/src/shared/models/Activity'
import Announcement from '~/client/src/shared/models/Announcement'
import CalendarEvent, {
  CalendarEventEntityType,
  CalendarEventLabelType,
} from '~/client/src/shared/models/CalendarEvent'
import Delivery from '~/client/src/shared/models/Delivery'
import SitePermit from '~/client/src/shared/models/Permit'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import ActivityFollowingsStore from '~/client/src/shared/stores/domain/ActivityFollowings.store'
import AnnouncementFollowingsStore from '~/client/src/shared/stores/domain/AnnouncementFollowings.store'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import CastFollowingsStore from '~/client/src/shared/stores/domain/CastFollowings.store'
import ClosureFollowingsStore from '~/client/src/shared/stores/domain/ClosureFollowings.store'
import ClosuresStore from '~/client/src/shared/stores/domain/Closures.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import GlobeViewsStore from '~/client/src/shared/stores/domain/GlobeViews.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import MaturixCastsStore from '~/client/src/shared/stores/domain/MaturixStores/MaturixCasts.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitePermitFollowingsStore from '~/client/src/shared/stores/domain/SitePermitFollowings.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import SitemapsStore from '~/client/src/shared/stores/domain/Sitemaps.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { OpenedFilterType } from '~/client/src/shared/types/OpenedFilterType'
import {
  getSearchParam,
  removeFromSearchParam,
  urlHasSearchParam,
} from '~/client/src/shared/utils/urlutils'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import ViewSelector, {
  ViewMode,
} from '../../../shared/components/ViewSelector/ViewSelector'
import BottomNavigationNavBar from '../../components/BottomNavigationNavBar/BottomNavigationNavBar'
import MobileFileInput from '../../components/FileInput/MobileFileInput'
import MobileEventStore from '../../stores/EventStore/MobileEvents.store'
import MobileInitialState from '../../stores/MobileInitialState'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'
import { NEW_ANNOUNCEMENT_ID } from '../AnnouncementView/AnnouncementView'
import MobileLogisticsCalendar from './components/LogisticsCalendar/LogisticsCalendar'
import MobileLogisticsList from './components/LogisticsList/LogisticsList'
import MobileLogisticsMap from './components/LogisticsMap'
import MobileLogisticsStore, {
  LogisticsPageViewMode,
} from './stores/MobileLogistics.store'
import MobileLogisticsFilterStore from './stores/MobileLogisticsFilter.store'
import MobileLogisticsListStore from './stores/MobileLogisticsList.store'
import MobileLogisticsMapStore from './stores/MobileLogisticsMap.store'

import './Logistics.scss'

const URL_ACTION_PARAM = 'action'
const ARROW_HEIGHT = 15
const newPermitRequest = (type: string) => `New ${type} request`
const newAnnouncement = 'New announcement'

export enum SectionNames {
  Announcements = 'Announcements',
  Forms = 'Forms',
  Deliveries = 'Deliveries',
  Activities = 'Activities',
}

export interface IProps {
  state?: MobileInitialState
  eventsStore?: MobileEventStore
  sitemapsStore?: SitemapsStore
  common?: MobileCommonStore
  projectDateStore?: ProjectDateStore
  announcementsStore?: AnnouncementsStore
  sitePermitsStore?: SitePermitsStore
  permitTypesStore?: PermitTypesStore
  deliveriesStore?: DeliveriesStore
  deliveryDetailsStore?: DeliveryDetailsStore
  deliveryFollowingsStore?: DeliveryFollowingsStore
  sitePermitFollowingsStore?: SitePermitFollowingsStore
  activityFollowingsStore?: ActivityFollowingsStore
  announcementFollowingsStore?: AnnouncementFollowingsStore
  activitiesStore?: ActivitiesStore
  statusUpdatesStore?: StatusUpdatesStore
  closuresStore?: ClosuresStore
  locationAttributesStore?: LocationAttributesStore
  companiesStore?: CompaniesStore
  maturixCastsStore?: MaturixCastsStore
  closureFollowingsStore?: ClosureFollowingsStore
  castFollowingsStore?: CastFollowingsStore
  projectMembersStore?: ProjectMembersStore
  tagsStore?: TagsStore
  globeViewsStore?: GlobeViewsStore

  isPermitOnly?: boolean
}

@inject(
  'state',
  'common',
  'eventsStore',
  'sitemapsStore',
  'projectDateStore',
  'announcementsStore',
  'sitePermitsStore',
  'permitTypesStore',
  'deliveriesStore',
  'deliveryDetailsStore',
  'activitiesStore',
  'statusUpdatesStore',
  'deliveryFollowingsStore',
  'sitePermitFollowingsStore',
  'activityFollowingsStore',
  'announcementFollowingsStore',
  'closuresStore',
  'locationAttributesStore',
  'companiesStore',
  'maturixCastsStore',
  'closureFollowingsStore',
  'castFollowingsStore',
  'projectMembersStore',
  'tagsStore',
  'globeViewsStore',
)
@observer
export default class Logistics extends React.Component<IProps> {
  private readonly store: MobileLogisticsStore
  private readonly logisticListStore: MobileLogisticsListStore
  private readonly logisticsMapStore: MobileLogisticsMapStore
  private readonly logisticsFilterStore: MobileLogisticsFilterStore = null
  @observable private isCreationPopupShown: boolean = false

  @observable private isDatePickerShown: boolean = false

  @observable private isPermitCreationFormShown: boolean = false
  @observable private isPermitViewFormShown: boolean = false
  @observable private displayedSitePermit: SitePermit = null

  private cancelUrlListener = null

  public constructor(props: IProps) {
    super(props)

    this.store = new MobileLogisticsStore(
      props.announcementsStore,
      props.sitePermitsStore,
      props.deliveriesStore,
      props.activitiesStore,
      props.projectDateStore,
      props.statusUpdatesStore,
      props.eventsStore,
      props.sitemapsStore,
      props.globeViewsStore,
      props.deliveryFollowingsStore,
      props.sitePermitFollowingsStore,
      props.activityFollowingsStore,
      props.announcementFollowingsStore,
      props.maturixCastsStore,
      props.closureFollowingsStore,
      props.castFollowingsStore,
      props.closuresStore,
      props.permitTypesStore,
      props.locationAttributesStore,
      props.companiesStore,
      props.isPermitOnly,
    )

    this.logisticListStore = new MobileLogisticsListStore(
      this.store,
      props.projectDateStore,
      props.projectMembersStore,
      props.companiesStore,
      props.locationAttributesStore,
      props.sitePermitsStore,
      props.permitTypesStore,
      this.showAnnouncement,
      this.openPermitViewForm,
      this.openActivity,
      this.openDeliveryViewForm,
      props.isPermitOnly,
    )

    this.logisticsMapStore = new MobileLogisticsMapStore(
      this.store,
      props.companiesStore,
      props.deliveriesStore,
      props.sitePermitsStore,
      props.activitiesStore,
    )

    this.logisticsFilterStore = new MobileLogisticsFilterStore(
      props.eventsStore,
      this.store,
      this.logisticListStore,
      this.onShowChanged,
      props.locationAttributesStore,
      props.permitTypesStore,
      props.tagsStore,
      props.isPermitOnly,
    )

    this.setViewModeFromHash()
    this.store.initPostEventCallback()
    this.store.ensureCorrectViewMode()
  }

  @action.bound
  public onShowChanged(isShown: boolean, popupType: OpenedFilterType) {
    //TODO: TEMPORARY
    return [isShown, popupType]
  }

  private setViewModeFromHash = () => {
    const hash = window.location.hash.replace('#', '')
    if (
      Object.values(LogisticsPageViewMode).includes(
        hash as LogisticsPageViewMode,
      )
    ) {
      this.store.setViewMode(hash as LogisticsPageViewMode)
    }
  }

  public componentDidMount(): void {
    const { common, state } = this.props

    common.hideNavBar()

    this.runActionFromURL()

    if (state.preSelectedPermitTypeId) {
      this.openPermitCreationForm()
    }

    this.cancelUrlListener = common.history.listen(this.runActionFromURL)
  }

  public componentWillUnmount(): void {
    this.store.clearPostEventCallback()
    this.cancelUrlListener()
  }

  public componentDidUpdate() {
    this.runActionFromURL()
  }

  @action.bound
  private runActionFromURL() {
    if (urlHasSearchParam(URL_ACTION_PARAM)) {
      const action = getSearchParam(URL_ACTION_PARAM)

      switch (action) {
        case 'createAnnouncement':
          if (this.isAdmin) {
            this.openAnnouncementCreationForm()
          }
          break
      }

      removeFromSearchParam(URL_ACTION_PARAM)
    }

    this.openSitePermitFromURL()
  }

  private get loaderText(): string {
    if (this.props.isPermitOnly) {
      return Localization.translator.loadingFormsData
    }

    return Localization.translator.loadingLogisticsData
  }

  public render(): JSX.Element {
    const {
      deliveryDetailsStore: { displayedDelivery },
      state: { activeProject },
      isPermitOnly,
    } = this.props
    const { id: activeProjectId } = activeProject

    if (this.isPermitCreationFormShown) {
      return (
        <SitePermitCreationForm
          close={this.closePermitCreationForm}
          FileInputType={MobileFileInput}
          calendarEventDates={this.store.calendarEventDates}
          isMobileMode={true}
        />
      )
    }

    if (this.isPermitViewFormShown) {
      return (
        <SitePermitViewForm
          permitToShow={this.displayedSitePermit}
          close={this.closePermitViewForm}
          FileInputType={MobileFileInput}
          isMobileMode={true}
        />
      )
    }

    if (!isPermitOnly && displayedDelivery) {
      return (
        <DeliveryDetails
          initProjectId={activeProjectId}
          FileInputType={MobileFileInput}
          backClicked={this.closeDeliveryViewForm}
          displayedDeliveryId={displayedDelivery?.id}
          isMobileMode={true}
        />
      )
    }

    const shouldBeScrollable =
      (this.store.selectedViewMode === LogisticsPageViewMode.List ||
        this.store.selectedViewMode === LogisticsPageViewMode.Calendar) &&
      !this.isDatePickerShown

    return (
      <View
        className={classList({
          logistics: true,
          scrollable: shouldBeScrollable,
          'overflow-hidden': !shouldBeScrollable,
        })}
      >
        <div className="col relative">
          <Header>{this.renderHeader()}</Header>
          <Content scrollable={false}>{this.content}</Content>
        </div>
        <Footer className="sticky logistics-footer">
          <BottomNavigationNavBar
            creationPopup={
              this.isCreationPopupShown && this.renderCreationPopup()
            }
            onCreateClicked={this.toggleCreationPopup}
          />
        </Footer>
      </View>
    )
  }

  private renderHeader(): JSX.Element {
    const { shouldShowHeader } = this.props.common
    if (!shouldShowHeader) {
      return null
    }

    const isTransparentHeader =
      this.store.selectedViewMode === LogisticsPageViewMode.Map &&
      !this.isDatePickerShown

    return (
      <div
        className={classList({
          'row px20 py5 logistics-header': true,
          'logistics-transparent-header': isTransparentHeader,
        })}
      >
        <DayBar
          className="bg-transparent"
          isArrowsHidden={true}
          shouldIconRender={false}
          arrowsHeight={ARROW_HEIGHT}
          currentStartDate={this.store.viewingDate}
          setDate={this.store.setViewingDate}
          isOneDayMode={true}
          setDatePickerState={this.setDatePickerState}
          shouldRenderChevron={true}
        />
        <ViewSelector
          viewModes={this.viewModes}
          selectedViewMode={this.store.selectedViewMode}
          selectViewMode={this.store.setViewMode}
        />
        {!this.props.isPermitOnly && this.renderNotifications()}
      </div>
    )
  }

  private renderNotifications(): JSX.Element {
    const {
      common: { displayNotifications },
      state: { unreadNotificationsCount },
    } = this.props

    return (
      <div
        className="ml10 row x-center no-grow relative notification-item"
        onClick={() => {
          displayNotifications()
        }}
      >
        <Icons.NotificationBell className="no-grow" />
        {unreadNotificationsCount > 0 && (
          <span className="bg-red text bold small white br-rounded row x-center notifications-badge">
            {unreadNotificationsCount}
          </span>
        )}
      </div>
    )
  }

  private get content(): JSX.Element {
    const { isPermitOnly } = this.props
    const { selectedViewMode } = this.store

    if (this.store.isLoaderShown) {
      return <Loader hint={this.loaderText} />
    }

    return (
      <>
        <div className="full-height relative">
          {selectedViewMode === LogisticsPageViewMode.Map && (
            <MobileLogisticsMap
              openPermitViewForm={this.openPermitViewForm}
              openDeliveryViewForm={this.openDeliveryViewForm}
              logisticsStore={this.store}
              logisticsMapStore={this.logisticsMapStore}
              showAnnouncement={this.showAnnouncement}
              isPermitOnly={isPermitOnly}
              logisticsFilterStore={this.logisticsFilterStore}
            />
          )}
          {selectedViewMode === LogisticsPageViewMode.List && (
            <MobileLogisticsList
              viewingDate={this.store.viewingDate}
              isPermitOnly={isPermitOnly}
              isListOnly={this.store.isMapViewDisabled}
              store={this.store}
              logisticsListStore={this.logisticListStore}
              logisticsFilterStore={this.logisticsFilterStore}
            />
          )}
          {selectedViewMode === LogisticsPageViewMode.Calendar && (
            <MobileLogisticsCalendar
              logisticsStore={this.store}
              onNewEventCreate={this.onNewEventCreate}
              onEventClicked={this.onEventClicked.bind(this)}
              logisticsFilterStore={this.logisticsFilterStore}
              logisticsListStore={this.logisticListStore}
              isPermitOnly={isPermitOnly}
            />
          )}
        </div>
      </>
    )
  }

  @action.bound
  public onNewEventCreate(startDate?: Date, endDate?: Date) {
    if (startDate && endDate) {
      this.setPlaceholderDates(startDate, endDate)
      this.openPermitCreationForm()
    }
  }

  public onEventClicked(event: CalendarEvent) {
    const isNewType = event.labelType === CalendarEventLabelType.New

    if (isNewType) return

    switch (event.entityType) {
      case CalendarEventEntityType.Form:
        this.openPermitViewForm(event.data)
        break
    }
  }

  private get isAdmin(): boolean {
    return this.props.state.userActiveProjectSettings?.isAdmin
  }

  private renderCreationPopup(): JSX.Element {
    const { permitTypesStore, isPermitOnly } = this.props

    return (
      <MenuCloser
        className="menu-popup absolute-block scrollable py10"
        closeMenu={this.closeCreationPopup}
        excludeId="logistic-menu-popup-button"
      >
        {this.isAdmin && !isPermitOnly && (
          <div
            className="px30 py12 row y-center"
            onClick={this.openAnnouncementCreationForm}
          >
            <Icons.Megaphone className="mw30 row pr8" />
            <div className="text extra-large brand-dark mx10">
              {newAnnouncement}
            </div>
          </div>
        )}
        {permitTypesStore.enabledTypes.map(type => {
          return (
            <div
              key={type.id}
              className="px24 py12 row y-center"
              onClick={this.openSpecificPermitCreationForm.bind(this, type.id)}
            >
              <PermitTypeIcon
                permitType={type.type}
                className="permit-icon mw30 row pr8"
              />
              <div className="text extra-large brand-dark mx10">
                {newPermitRequest(type.name)}
              </div>
            </div>
          )
        })}
      </MenuCloser>
    )
  }

  private setPlaceholderDates = (startDate: Date, endDate: Date): void => {
    this.store.setPlaceholderStartDate(startDate)
    this.store.setPlaceholderEndDate(endDate)
  }

  private toggleCreationPopup = (shouldResetDates: boolean = true): void => {
    if (shouldResetDates) {
      this.store.resetPlaceholderDates()
    }
    this.isCreationPopupShown = !this.isCreationPopupShown
  }

  private closeCreationPopup = (): void => {
    this.isCreationPopupShown = false
  }

  private openAnnouncementCreationForm = (): void => {
    this.closeCreationPopup()

    if (!this.isAdmin) {
      return
    }

    this.props.common.displayAnnouncementView(NEW_ANNOUNCEMENT_ID)
  }

  private showAnnouncement = (announcement: Announcement): void => {
    if (!announcement) {
      return
    }

    removeFromSearchParam(UrlParamKey.Announcement)
    this.props.common.displayAnnouncementView(announcement.id)
  }

  @action.bound
  private openPermitCreationForm() {
    this.isPermitCreationFormShown = true
  }

  private openPermitViewForm = (permit: SitePermit): void => {
    this.displayedSitePermit = permit
    this.isPermitViewFormShown = true
  }

  private openDeliveryViewForm = (delivery: Delivery): void => {
    this.props.deliveryDetailsStore.setViewMode(delivery)
  }

  private openActivity = (activity: Activity): void => {
    this.props.activitiesStore.select(activity.code)
  }

  private closeDeliveryViewForm = (): void => {
    this.props.deliveryDetailsStore.displayedDelivery = null
  }

  private closePermitCreationForm = (): void => {
    this.isPermitCreationFormShown = false
  }

  private closePermitViewForm = (): void => {
    this.isPermitViewFormShown = false
    if (window.location.search.includes(UrlParamKey.Form)) {
      // force URL params out of the history
      const { common } = this.props
      if (common.isFormsDisplayed) {
        common.displayFormsView()
      } else {
        common.displayHomeView()
      }
    }
  }

  private openSpecificPermitCreationForm = (permitTypeId: string): void => {
    const { state } = this.props
    state.preSelectedPermitTypeId = permitTypeId
    this.openPermitCreationForm()
    this.closeCreationPopup()
  }

  private openSitePermitFromURL(): void {
    const { sitePermitsStore, announcementsStore } = this.props

    const queryBaseStoreMap = {
      [UrlParamKey.Announcement]: {
        store: announcementsStore,
        action: item => {
          this.showAnnouncement(item)
        },
      },
      [UrlParamKey.Form]: {
        store: sitePermitsStore,
        action: item => {
          this.openPermitViewForm(item)
        },
      },
    }

    const urlParams = new URLSearchParams(location.search)

    const queryParams = Array.from(urlParams.keys())
    const lastQueryParam = queryParams[queryParams.length - 1] || EMPTY_STRING

    const storeActionPair = queryBaseStoreMap[lastQueryParam]

    if (!lastQueryParam || !storeActionPair) {
      return
    }

    const itemId = urlParams.get(lastQueryParam)

    if (!itemId || !storeActionPair.store.isDataReceived) return

    const item = storeActionPair.store.byId.get(itemId)

    if (item) {
      storeActionPair.action(item)
    }
  }

  private get viewModes(): ViewMode[] {
    return [
      this.props.isPermitOnly && {
        mode: LogisticsPageViewMode.Calendar,
        Icon: Icons.CalendarGrey,
      },
      {
        mode: LogisticsPageViewMode.List,
        Icon: Icons.List,
      },
      {
        mode: LogisticsPageViewMode.Map,
        Icon: Icons.Sitemap,
        isDisabled: this.store.isMapViewDisabled,
      },
    ].filter(vm => !!vm)
  }

  @action.bound
  private setDatePickerState(isShown: boolean) {
    this.isDatePickerShown = isShown
  }
}
