import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import mobileRoutes from '~/client/src/mobile/constants/mobileRoutes'
import ChatIconWithBadge from '~/client/src/shared/components/ChatIconWithBadge/ChatIconWithBadge'
import * as Icons from '~/client/src/shared/components/Icons'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ChatService from '~/client/src/shared/services/ChatService/Chat.service'

import MobileInitialState from '../../stores/MobileInitialState'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

import './BottomNavigationNavBar.scss'

interface IProps {
  onCreateClicked?(): void
  creationPopup?: JSX.Element

  state?: MobileInitialState
  common?: MobileCommonStore
  chatService?: ChatService
}

interface IButton {
  title: string
  icon: any
  onClick: () => void
}

@inject('state', 'common', 'chatService')
@observer
export default class BottomNavigationNavBar extends React.Component<IProps> {
  @observable private isMenuPopupShown: boolean = false

  public render() {
    if (!this.props.common.shouldShowBottomNavBar) {
      return null
    }

    const {
      onCreateClicked,
      state: { isLogisticsDisabled },
      creationPopup,
    } = this.props

    return (
      <div className="bottom-navigation-nav-bar">
        {this.isMenuPopupShown && this.renderMenu()}
        <div className="bottom-navigation-nav-bar-scrollable row pb10">
          {this.renderSideBarMenuButton()}
          {!isLogisticsDisabled && this.renderNavigateButton(mobileRoutes.HOME)}
          {this.renderNavigateMenuButton()}
          {creationPopup}
          {this.renderNavigateButton(mobileRoutes.CHAT)}
          {!!onCreateClicked && (
            <div className="row ml10 mw55">
              <Icon
                id="logistic-menu-popup-button"
                icon={IconNames.PLUS}
                className="create-icon pa10 br-rounded"
                onClick={onCreateClicked}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  private renderSideBarMenuButton(): JSX.Element {
    return (
      <div
        onClick={this.props.common.toggleSidebar}
        className="navigate-button full-height col pa6 x-center y-center no-flex"
      >
        <Icon icon={IconNames.MENU} className="fill-path" />
        <div className="text grey-light pt2">{'Menu'}</div>
      </div>
    )
  }

  private renderNavigateButton(route: string): JSX.Element {
    const { title, icon, onClick } = this.buttonByRoute(route)
    return (
      <div className={this.getOptionColoringClassName(route)} onClick={onClick}>
        {icon}
        <div className="text grey-light pt2">{title}</div>
      </div>
    )
  }

  private renderNavigateMenuButton(): JSX.Element {
    const { title, icon } = this.buttonByRoute(this.routeActiveApp)

    return (
      <div
        className={classList({
          [this.getOptionColoringClassName(this.routeActiveApp)]: true,
          relative: true,
          'unclickable-element': this.isMenuPopupShown,
        })}
        onClick={this.toggleMenuButton}
      >
        {icon}
        <div className="text grey-light pt2">{title}</div>
        <span className="arrow absolute no-grow">
          {this.isMenuPopupShown ? (
            <Icon icon={IconNames.CARET_DOWN} className="open" />
          ) : (
            <Icon icon={IconNames.CARET_UP} className="close" />
          )}
        </span>
      </div>
    )
  }

  private renderMenu() {
    const { availableAppRoutes } = this.commonStore

    return (
      <MenuCloser
        className="menu-popup absolute-block bg-white"
        closeMenu={this.toggleMenuButton}
      >
        {availableAppRoutes.map((route, i) => {
          const { title, icon, onClick } = this.buttonByRoute(route)
          const isFirst = i === 0
          return (
            <div
              key={i}
              className={classList({
                [this.getOptionColoringClassName(route, true)]: true,
                'bt-light-grey': !isFirst,
                first: isFirst,
                last: i === availableAppRoutes.length - 1,
              })}
              onClick={onClick}
            >
              {icon}
              <div className="text extra-large brand-dark mx15">{title}</div>
              {route === this.routeActiveApp && (
                <Icon icon={IconNames.TICK} className="ml-auto" />
              )}
            </div>
          )
        })}
      </MenuCloser>
    )
  }

  private toggleMenuButton = () => {
    this.isMenuPopupShown = !this.isMenuPopupShown
  }

  private openHome = () => {
    this.commonStore.displayHomeView()
  }

  private openForms = () => {
    this.commonStore.displayForms()
  }

  private openActivities = () => {
    this.commonStore.displayActivityList()
  }

  private openDeliveries = () => {
    this.commonStore.displayDeliveriesView()
  }

  private openChat = () => {
    this.commonStore.displayChatView()
  }

  private openPhotos = () => {
    this.commonStore.displayPhotos()
  }

  private openNotifications = () => {
    this.commonStore.displayNotifications()
  }

  private openQRCOdes = () => {
    this.commonStore.displayQRCodes()
  }

  private getOptionColoringClassName(option: string, isRow?: boolean): string {
    let isOptionSelected = false

    switch (option) {
      case mobileRoutes.ACTIVITIES:
        isOptionSelected = this.commonStore.isActivityListDisplayed
        break
      case mobileRoutes.DELIVERIES:
        isOptionSelected = this.commonStore.isDeliveryListDisplayed
        break
      case mobileRoutes.FORMS:
        isOptionSelected = this.commonStore.isFormsDisplayed
        break
      case mobileRoutes.HOME:
        isOptionSelected = this.commonStore.isLogisticsDisplayed
        break
      case mobileRoutes.CHAT:
        isOptionSelected = this.commonStore.isChatDisplayed
        break
      case mobileRoutes.DOCUMENTS:
        isOptionSelected = this.commonStore.isPhotosDisplayed
        break
      case mobileRoutes.NOTIFICATIONS:
        isOptionSelected = this.commonStore.isNotificationsDisplayed
        break
      case mobileRoutes.QR_CODES:
        isOptionSelected = this.commonStore.isQRCodesDisplayed
        break
      default:
        isOptionSelected = this.isMenuPopupShown
        break
    }

    return classList({
      'menu-button row no-flex-children px15': isRow,
      'navigate-button full-height col pa6 x-center': !isRow,
      'y-center no-flex': true,
      selected: isOptionSelected,
    })
  }

  private get commonStore(): MobileCommonStore {
    return this.props.common
  }

  private get routeActiveApp(): string {
    if (this.commonStore.isActivityListDisplayed) {
      return mobileRoutes.ACTIVITIES
    }
    if (this.commonStore.isDeliveryListDisplayed) {
      return mobileRoutes.DELIVERIES
    }
    if (this.commonStore.isFormsDisplayed) {
      return mobileRoutes.FORMS
    }
    if (this.commonStore.isPhotosDisplayed) {
      return mobileRoutes.DOCUMENTS
    }
    if (this.commonStore.isNotificationsDisplayed) {
      return mobileRoutes.NOTIFICATIONS
    }
    if (this.commonStore.isQRCodesDisplayed) {
      return mobileRoutes.QR_CODES
    }
  }

  private buttonByRoute = (route: string): IButton => {
    switch (route) {
      case mobileRoutes.HOME:
        return {
          title: Localization.translator.home,
          icon: <Icons.Home />,
          onClick: this.openHome,
        }
      case mobileRoutes.FORMS:
        return {
          title: Localization.translator.forms,
          icon: <Icons.GeneralForm className="fill-path" />,
          onClick: this.openForms,
        }
      case mobileRoutes.DELIVERIES:
        return {
          title: Localization.translator.deliveries,
          icon: <Icons.Truck className="deliveries-icon fill-path" />,
          onClick: this.openDeliveries,
        }
      case mobileRoutes.ACTIVITIES:
        return {
          title: Localization.translator.schedule,
          icon: <Icons.Gantt />,
          onClick: this.openActivities,
        }
      case mobileRoutes.CHAT:
        return {
          title: Localization.translator.chat,
          icon: (
            <ChatIconWithBadge
              count={this.props.chatService.allUnreadMessagesCount}
              className="fill-path"
            />
          ),
          onClick: this.openChat,
        }
      case mobileRoutes.DOCUMENTS:
        return {
          title: Localization.translator.photos,
          icon: <Icon icon={IconNames.MEDIA} className="fill-path" />,
          onClick: this.openPhotos,
        }
      case mobileRoutes.NOTIFICATIONS:
        return {
          title: Localization.translator.notifications,
          icon: <Icons.Bell className="fill-path" />,
          onClick: this.openNotifications,
        }
      case mobileRoutes.QR_CODES:
        return {
          title: Localization.translator.scannerApp,
          icon: <Icons.QRCode className="fill-path" />,
          onClick: this.openQRCOdes,
        }
      default:
        return {
          title: Localization.translator.apps,
          icon: <Icons.LogoMenu className="fill-path" />,
          onClick: this.toggleMenuButton,
        }
    }
  }
}
