import mobileRoutes, {
  MobileRoute,
  isMobileRoute,
} from '~/client/src/mobile/constants/mobileRoutes'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import {
  UrlParamKey,
  isCommonRoute,
} from '~/client/src/shared/constants/commonRoutes'
import ThemeModeManager, {
  ThemeModes,
} from '~/client/src/shared/utils/ThemeModeManager'

type LogMessage = {
  type: 'log'
  message: string
}

type IntegratorAction =
  | 'INIT'
  | 'INIT-SUCCESS'
  | 'LISTEN-TO-NAVIGATION'
  | 'REPORT-NAVIGATION'
  | 'NAVIGATE'
  | 'REPORT-DOCUMENT-HEIGHT'
  | 'SET-THEME-MODE'
  | 'OVERRIDE-BOTTOM-NAVBAR'
  | 'TOGGLE-BOTTOM-NAVBAR'
  | 'SHOW-HEADER'
  | 'OVERRIDE-SIDEBAR'
  | 'TOGGLE-SIDEBAR'

type ActionMessage = {
  type: 'action'
  action: IntegratorAction
  payload?: any
}

type Message = LogMessage | ActionMessage

type NavigationMessage = {
  route: MobileRoute
  params?: any
}

/**
 * This class is used to communicate with the native app.
 * It exposes a façade that can be used from the mobile app.
 * To communicate with the native app, use the `send` method.
 */
export default class NativeIntegrator {
  public static instance: NativeIntegrator
  public constructor(private readonly common: MobileCommonStore) {
    // Only initialize if we're running in a WebView
    if (!window.ReactNativeWebView) return

    this.init()

    // Make the Integrator reachable from anywhere
    // This is not quite a singleton class: more than one instance can be created, but only the first one will be reachable.
    if (!NativeIntegrator.instance) {
      NativeIntegrator.instance = this
    }
  }

  public static log(message: string, ...params: any[]): void {
    NativeIntegrator.instance?.log(message, ...params)
  }

  /**
   * Logs a message in the native app's console.
   * @param message - The message to send.
   * @param params - Any additional parameters to send.
   * @example
   * this.log('Hello world!')
   * this.log('Hello world!', 'foo', 'bar')
   * this.log('Hello world!', { foo: 'bar' })
   */
  public log(message: string, ...params: any[]): void {
    if (params) {
      message += ` ${params.map(p => JSON.stringify(p)).join(' ')}`
    }
    this.send({ type: 'log', message: message.trim() })
    console.log(message, ...params)
  }

  /**
   * Tells the native app to set the theme mode.
   * @param mode - The theme mode to set. Either 'dark-mode' or 'light-mode'.
   */
  public setMobileThemeMode(mode: ThemeModes) {
    this.log('setMobileThemeMode', mode)
    this.send({
      type: 'action',
      action: 'SET-THEME-MODE',
      payload: mode,
    })
  }

  public toggleNativeSidebar = () => {
    this.log('toggleNativeSidebar')
    this.send({
      type: 'action',
      action: 'TOGGLE-SIDEBAR',
    })
  }

  public toggleBottomNavbar = (toggle: boolean) => {
    this.log('toggleNativeBottomNavBar')
    this.send({
      type: 'action',
      action: 'TOGGLE-BOTTOM-NAVBAR',
      payload: toggle,
    })
  }

  public reportDocumentHeight(height: number) {
    this.send({
      type: 'action',
      action: 'REPORT-DOCUMENT-HEIGHT',
      payload: height,
    })
  }

  public navigateToRoute(route: MobileRoute, params: any) {
    if (!isMobileRoute(route) && !isCommonRoute(route)) {
      console.warn(`${route} is not a valid route.`)
      return
    }

    // Choose by route names, not their values
    // Mobile routes first
    switch (route) {
      case 'PHOTO_DETAIL':
        this.common.displayPhotoView(params)
        break

      case 'FLAG':
        this.common.displayFlagView(params)
        break

      case 'RFI':
        this.common.displayRfiView(params)
        break

      case 'SCHEDULE_COMMENT':
        this.common.displayScheduleCommentView(params)
        break

      case 'CATEGORY_OF_VARIANCE':
        this.common.displayCategoryOfVarianceView(params)
        break

      case 'SAFETY_HAZARD':
        this.common.displaySafetyHazardView(params)
        break

      case 'STATUS_UPDATE_MESSAGES':
        this.common.displayStatusUpdateMessagesView(params)
        break

      case 'FORM_VIEW':
        this.common.displayFormView(params)
        break

      case 'DOCUMENTS':
        this.common.displayPhotos()
        break

      case 'BULK_STATUS_UPDATE':
        this.common.displayBulkStatusUpdateView(params.activity, params.company)
        break

      case 'USERS_DIRECTORY':
        this.common.displayUsersDirectoryView()
        break

      case 'USER_PROFILE':
        this.common.displayUserProfileView(params)
        break

      case 'CHAT':
        this.common.displayChatView()
        break

      case 'QR_CODES':
        this.common.displayQRCodes()
        break

      // Common routes
      case 'LOGIN':
        this.common.displayLoginView()
        break

      case 'SAVE_PASSWORD':
        this.common.displaySavePasswordView()
        break

      case 'RESET_PASSWORD':
        this.common.displayResetPasswordView()
        break

      case 'INFO':
        this.common.displayInfoPage(params?.errorCode, params?.payload)
        break

      case 'FORMS':
        this.common.displayFormsView()
        break

      case 'FORM_TYPES':
        this.common.displayFormTypesView()
        break

      case 'HOME':
        this.common.displayHomeView(params)
        break

      case 'DELIVERIES':
        const viewMode = params?.viewMode
        this.common.displayDeliveriesView(viewMode)
        break

      case 'DELIVERY_DETAILS':
        this.common.displayDeliveryDetailsView(params?.id)
        break

      case 'ACTIVITIES':
        this.common.displayActivityList()
        break

      case 'ACTIVITY_DETAIL':
        this.common.displayActivityDetailsView(params)
        break

      case 'NOTIFICATIONS':
        this.common.displayNotifications(params?.id)
        break

      case 'NOTIFICATION_SETTINGS':
        this.common._displayView(mobileRoutes.NOTIFICATION_SETTINGS)
        break

      default:
        this.common._displayView(route as string)
    }
  }

  /**
   * Expose a façade to the app that can be used from the mobile app.
   */
  private init() {
    this.log('NativeIntegrator.init()')

    const urlParams = new URLSearchParams(window.location.search)

    // Override the web bottom navbar and sidebar immediately if requested by the URL
    if (urlParams.get(UrlParamKey.OverrideNavBar)) {
      this.overrideWebBottomNavbar()
      this.overrideWebSidebar()
    }

    window.stx = {
      processMessage: (message: ActionMessage) => {
        this.log('Web processMessage', message)
        try {
          const { type, action, payload } = message
          if (type !== 'action') return

          switch (action) {
            case 'LISTEN-TO-NAVIGATION':
              this.listenToNavigation()
              break

            case 'NAVIGATE':
              this.navigate(payload)
              break

            case 'OVERRIDE-BOTTOM-NAVBAR':
              this.overrideWebBottomNavbar()
              break

            case 'OVERRIDE-SIDEBAR':
              this.overrideWebSidebar(payload?.override ?? true)
              break

            case 'SHOW-HEADER':
              this.showWebAppHeader(payload)
              break

            case 'SET-THEME-MODE':
              if (typeof payload === 'string') {
                ThemeModeManager.setMode(payload as ThemeModes)
              }
              break

            default:
              this.log(`Unknown action: ${action}`)
          }
        } catch (e) {
          this.log('Error processing message', e)
        }
      },
    }

    this.send({
      type: 'action',
      action: 'INIT',
    })
  }

  private listenToNavigation() {
    this.common.listenToNavigation(path => {
      this.send({
        type: 'action',
        action: 'REPORT-NAVIGATION',
        payload: path,
      })
    })
    this.log('NativeIntegrator is listening to embedded navigation.')
  }

  private navigate({ route, params }: NavigationMessage) {
    this.log('NativeIntegrator.navigate', route, params)
    if (route) {
      this.navigateToRoute(route, params)
    } else {
      this.log('NativeIntegrator.navigate: invalid route', route)
    }
  }

  private overrideWebBottomNavbar() {
    // If the bottom navbar is already overridden, remove the override first
    this.common.overrideBottomNavBar(null)

    this.log('NativeIntegrator.overrideWebBottomNavbar')
    this.common.hideBottomNavBar()
    this.common.overrideBottomNavBar(this.toggleBottomNavbar)
  }

  private overrideWebSidebar(override = true) {
    this.log('NativeIntegrator.overrideWebSidebar', override)
    if (override) {
      this.common.overrideSidebar(this.toggleNativeSidebar)
    } else {
      this.common.overrideSidebar(null)
    }
  }

  private showWebAppHeader(show: boolean) {
    this.log('NativeIntegrator.showLogisticsWebHeader', show)
    if (show) {
      this.common.showAppHeader()
    } else {
      this.common.hideAppHeader()
    }
  }

  /**
   * Send a message to the native app.
   * @private
   * @param message - The message to send. It must be a JSON-serializable object.
   * Currently supported message `type`s are:
   * - `log`: Log a message to the native app's console.
   * - `action`: Dispatch an action to the native app. Requires an `action` name; optional `payload` property can pass parameters.
   */
  private send(message: Message) {
    window.ReactNativeWebView?.postMessage(JSON.stringify(message))
  }
}
