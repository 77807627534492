import { computed } from 'mobx'

import mobileRoutes from '~/client/src/mobile/constants/mobileRoutes'
import SectionNames from '~/client/src/shared/enums/SectionNames'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  GET_PROJECT_MEMBERS,
  LOGOUT,
  SHOW_ONE_TIME_POPUP,
} from '~/client/src/shared/stores/EventStore/eventConstants'

import MobileEventStore from '../../stores/EventStore/MobileEvents.store'
import MobileInitialState from '../../stores/MobileInitialState'
import MobileCommonStore from '../../stores/ui/MobileCommon.store'

interface ISidebarSection {
  id: SectionNames
  route: string
  isDisabled?: boolean
}

export interface ISidebarItem extends ISidebarSection {
  name: string
}

export default class SidebarStore {
  private readonly sidebarSections: ISidebarSection[] = [
    {
      id: SectionNames.PROFILE,
      route: mobileRoutes.USER_PROFILE,
    },
    {
      id: SectionNames.USERS,
      route: mobileRoutes.USERS_DIRECTORY,
    },
    /* TODO: improve this feature
    {
      id: SectionNames.ADD_TEAMMATES,
      route: mobileRoutes.ADD_TEAMMATE,
    },
    */
    {
      id: SectionNames.QR_CODES,
      route: mobileRoutes.QR_CODES,
    },
    {
      id: SectionNames.NOTIFICATION_SETTINGS,
      route: mobileRoutes.NOTIFICATION_SETTINGS,
    },

    // TODO: detect if the app is installed on the device. Do not show this section if it is.
    // Only add this section if the app is not running in a WebView
    ...(window.ReactNativeWebView
      ? []
      : [
          {
            id: SectionNames.ONE_TIME_POPUP,
            route: mobileRoutes.ONE_TIME_POPUP,
          },
        ]),
  ]

  public constructor(
    private readonly common: MobileCommonStore,
    private readonly eventsStore: MobileEventStore,
    private readonly state: MobileInitialState,
  ) {}

  @computed
  public get sidebarItems() {
    return this.sidebarSections.map(sidebarSection => ({
      ...sidebarSection,
      name: this.getSectionName(sidebarSection.id),
    }))
  }

  private getSectionName = (name: SectionNames) => {
    switch (name) {
      case SectionNames.QR_CODES:
        return Localization.translator.scannerApp
      case SectionNames.ADD_TEAMMATES:
        return Localization.translator.addTeammates
      case SectionNames.USERS:
        return Localization.translator.userDirectory
      case SectionNames.PROFILE:
        return Localization.translator.myUserProfile
      case SectionNames.NOTIFICATION_SETTINGS:
        return Localization.translator.notificationPreferences
      case SectionNames.ONE_TIME_POPUP:
        return Localization.translator.getMobileNativeApp
    }
  }

  public onClickHandler = (isDisabled: boolean, route: string) => {
    if (isDisabled) {
      return
    }

    if (route === mobileRoutes.USER_PROFILE) {
      route = mobileRoutes.USER_PROFILE.replace(':userId', this.state.user.id)
    }

    this.common.toggleSidebar()

    if (route === mobileRoutes.ONE_TIME_POPUP) {
      return this.eventsStore.dispatch(SHOW_ONE_TIME_POPUP)
    }

    this.common._displayView(route)
  }

  public logoutHandler = () => {
    this.common.toggleSidebar()
    this.eventsStore.dispatch(LOGOUT)
  }

  public get isSidebarOpened() {
    return this.common.isSidebarOpen
  }

  public get activeProject() {
    return this.state.activeProject
  }

  public get areProjectMembersLoading() {
    return this.state.loading.get(GET_PROJECT_MEMBERS)
  }
}
