import React from 'react'

import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import SitemapAttributeIconComponent from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeIcon'
import ILogisticItem, {
  LogisticItemApp,
} from '~/client/src/shared/models/ILogisticItem'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import PermitType from '~/client/src/shared/models/PermitType'
import { NOOP } from '~/client/src/shared/utils/noop'

import './LogisticsListCard.scss'

interface IProps {
  item: ILogisticItem
  onClick?: () => void
  renderStatus: (item: ILogisticItem) => JSX.Element
  renderDates: (item: ILogisticItem) => JSX.Element
  getCompanyNameById: (companyId: string, placeholder?: string) => string
  getTypeName: (type: string) => PermitType
}

export default class LogisticsListCard extends React.Component<IProps> {
  public render() {
    const { item, getCompanyNameById, getTypeName, renderDates, renderStatus } =
      this.props
    const { name, locations, equipment, companyIds, app, type } = item

    const companyNames = companyIds
      ?.map(id => getCompanyNameById(id))
      .join(', ')
    const cardTitle = (
      type ? getTypeName(type)?.name || type : app
    ).toUpperCase()

    return (
      <div
        className={classList({
          'pa12 bb-light-grey logistics-list-card text large overflow-hidden':
            true,
        })}
        onClick={this.props.onClick || NOOP}
      >
        <div className="ml30">
          <span className="text line-24 large light">{cardTitle}</span>
        </div>
        <div className="row align-start">
          <div className="mw20">{this.renderIcon(app, type)}</div>
          <div className="text large line-24 px10 break-word">
            {companyNames && (
              <>
                <b>{companyNames}</b> •{' '}
              </>
            )}
            {name}
          </div>
          <div className="text line-24 end w-fit-content">
            {renderStatus(item)}
          </div>
        </div>
        <span className="text large line-24 ml30 inline-block">
          {renderDates(item)}
        </span>
        <div className="row text line-24 wrap no-flex-children overflow-hidden relative ml30">
          {locations.map((l, i) =>
            this.renderLocation(l, i < locations.length - 1),
          )}
        </div>
        <div className="row text line-24 wrap no-flex-children overflow-hidden relative ml30">
          {equipment.map((l, i) =>
            this.renderLocation(l, i < equipment.length - 1),
          )}
        </div>
      </div>
    )
  }

  private renderLocation = (
    location: LocationBase,
    shouldAddSeparator?: boolean,
  ): JSX.Element => {
    const { id, name, color } = location

    return (
      <div key={id} className="row no-flex-children my2 text large">
        <div
          className={classList({
            'h20 w20 location-icon': true,
            'no-fill': !!color,
          })}
          style={{ color }}
        >
          <SitemapAttributeIconComponent dataObject={location} />
        </div>
        <span className="location-name ml2" style={{ color }}>
          {name}
        </span>
        {shouldAddSeparator && <span className="text light mx4">•</span>}
      </div>
    )
  }

  private renderIcon(app: LogisticItemApp, subType?: string) {
    switch (app) {
      case LogisticItemApp.SCHEDULE:
        return <Icons.GanttSharp className="no-grow mr4 main-icon" />
      case LogisticItemApp.ANNOUNCEMENT:
        return <Icons.MegaphoneStencil className="no-grow mr4 main-icon" />
      case LogisticItemApp.DELIVERY:
        return <Icons.DeliveryOutline className="no-grow mr4 main-icon" />
      case LogisticItemApp.FORM:
        return (
          <PermitTypeIcon
            permitType={subType}
            className="permit-icon main-icon no-grow mr4"
          />
        )
    }
  }
}
