import { action, observable } from 'mobx'

import { DefaultLandingPage, NotificationType } from '~/client/graph'
import mobileRoutes from '~/client/src/mobile/constants/mobileRoutes'
import MobileRootStore from '~/client/src/mobile/stores/MobileRoot.store'
import commonRoutes, {
  bareRoutes,
  getURLParam,
  isDeliveryInfoPageRequested,
} from '~/client/src/shared/constants/commonRoutes'
import Activity from '~/client/src/shared/models/Activity'
import CategoryOfVariance from '~/client/src/shared/models/CategoryOfVariance'
import Flag from '~/client/src/shared/models/Flag'
import BaseNotification from '~/client/src/shared/models/Notification'
import Photo from '~/client/src/shared/models/Photo'
import Rfi from '~/client/src/shared/models/Rfi'
import SafetyHazard from '~/client/src/shared/models/SafetyHazard'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import User from '~/client/src/shared/models/User'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import {
  isAnnouncementType,
  isDeliveryType,
  isFlagType,
  isPermitType,
  isRFIType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '~/client/src/shared/types/NotificationTypes'
import { UNASSIGNED_FILTER_VALUE } from '~/client/src/shared/utils/ZoneLevelLocationConstants'

import NativeIntegrator from '../../integration/NativeIntegrator/NativeIntegrator'
import MobileInitialState from '../MobileInitialState'

export default class MobileCommonStore extends CommonStore {
  @observable public isSidebarOpen = false
  public bottomNavBarOverride: (state: boolean) => void | null = null
  public sidebarOverride: () => void | null = null
  @observable public shouldShowBottomNavBar: boolean = true
  @observable public shouldShowHeader = true
  @observable public shouldHideNavBar = false

  private navBarRef = null

  public constructor(
    protected state: MobileInitialState,
    protected rootStore: MobileRootStore,
  ) {
    super(state, rootStore)
    new NativeIntegrator(this)
  }

  @action.bound
  public showBottomNavBar() {
    if (this.bottomNavBarOverride) {
      this.bottomNavBarOverride(true)
      return
    }
    this.shouldShowBottomNavBar = true
  }

  @action.bound
  public hideBottomNavBar() {
    this.shouldShowBottomNavBar = false

    if (this.bottomNavBarOverride) {
      this.bottomNavBarOverride(false)
      return
    }
  }

  /**
   * Overriding allows an external entity (e.g. native app) to control the sidebar.
   * This is useful if the native app wants to display its own sidebar.
   * @param callback Each time `toggleSidebar` is called, this callback will be called instead, and the actual sidebar will remain hidden. The override is removed by passing `null` as the callback.
   */
  @action.bound
  public overrideSidebar(callback: null | (() => void)) {
    this.sidebarOverride = callback
  }

  /**
   * Overriding allows an external entity (e.g. native app) to control the bottom nav bar.
   * This is useful if the native app wants to display its own bottom nav bar.
   * @param callback Each time `showBottomNavBar` or `hideBottomNavBar` is called, this callback will be called instead, and the actual navbar will remain hidden. The override is removed by passing `null` as the callback.
   */
  @action.bound
  public overrideBottomNavBar(callback: null | ((state: boolean) => void)) {
    this.bottomNavBarOverride = callback
  }

  @action.bound
  public showAppHeader() {
    this.shouldShowHeader = true
  }

  @action.bound
  public hideAppHeader() {
    this.shouldShowHeader = false
  }

  public displayAuthSuccessRoute = () => {
    // Don't redirect when in webview, let the given route handle it
    if (window.ReactNativeWebView) return

    this.displayProjects()
  }

  @action.bound
  public toggleSidebar(evt?: any) {
    evt?.stopPropagation()

    if (this.sidebarOverride) {
      this.sidebarOverride()
      return
    }

    if (this.root.auth.isAuthenticated) {
      this.isSidebarOpen = !this.isSidebarOpen
    }
  }

  public toggleNavBar(evt?: any) {
    this.setNavBar(evt)
  }

  public get isFullHeight(): boolean {
    return this.shouldHideNavBar
  }

  public showNavBar = (evt?: any) => {
    this.setNavBar(evt, false)
  }

  public setNavBarRef = ref => {
    this.navBarRef = ref
  }

  @action.bound
  private setNavBar(evt: any, value?: boolean) {
    if (evt) {
      evt.stopPropagation()
    }

    if (value === this.shouldHideNavBar) {
      return Promise.resolve()
    }

    if (this.navBarRef === null) {
      console.warn(
        'WARNING: common.setNavBar called without navBar ref. All Promises will immediate resolve.',
      )
      return Promise.resolve()
    }

    let animEnded
    const animPromise = new Promise(r => (animEnded = r))
    const handleAnimEnd = () => {
      animEnded()
      this.navBarRef.removeEventListener('transitionend', handleAnimEnd)
    }

    this.navBarRef.addEventListener('transitionend', handleAnimEnd)
    this.shouldHideNavBar = value !== undefined ? value : !this.shouldHideNavBar
    return animPromise
  }

  public hideNavBar = () => {
    this.shouldHideNavBar = true
  }

  public get isActivityListDisplayed() {
    return location.pathname.includes(bareRoutes.ACTIVITIES)
  }

  public get isDeliveryListDisplayed() {
    return location.pathname.includes(bareRoutes.DELIVERIES)
  }

  public get isLogisticsDisplayed() {
    return location.pathname.includes(bareRoutes.HOME)
  }

  public get isFormsDisplayed() {
    return location.pathname.includes(bareRoutes.FORMS)
  }

  public get isUsersDirectoryDisplayed() {
    return location.pathname.includes(bareRoutes.USERS_DIRECTORY)
  }

  public get isPhotosDisplayed() {
    // TODO: Share-002
    const { pathname } = this.history.location
    return pathname === mobileRoutes.DOCUMENTS
  }

  public get isNotificationsDisplayed() {
    return location.pathname.includes(bareRoutes.NOTIFICATIONS)
  }

  public get isQRCodesDisplayed() {
    return location.pathname.includes(bareRoutes.QR_CODES)
  }

  public get isChatDisplayed(): boolean {
    // TODO: Share-002
    return this.history.location.pathname === mobileRoutes.CHAT
  }

  public get availableAppRoutes(): string[] {
    const { isDeliveriesDisabled, isTrackerDisabled, isFormsDisabled } =
      this.state

    return [
      !isDeliveriesDisabled && mobileRoutes.DELIVERIES,
      !isFormsDisabled && mobileRoutes.FORMS,
      !isTrackerDisabled && mobileRoutes.ACTIVITIES,
      mobileRoutes.QR_CODES,
      mobileRoutes.NOTIFICATIONS,
      mobileRoutes.DOCUMENTS,
    ].filter(route => !!route)
  }

  public goBack(
    altBackTransitionCb: () => void = this.displayDefaultView,
    steps: number = -1,
  ) {
    // presence 'key' indicates that 'history.push' calls have already been made within the current session
    if (this.history.location.key) {
      this.history.go(steps)
    } else {
      altBackTransitionCb()
    }
  }

  @action.bound
  public activityDetailsBackClicked() {
    const selectedActivityUrl = this.selectedActivity
      ? mobileRoutes.ACTIVITY_DETAIL.replace(
          ':code',
          this.selectedActivity.code,
        )
      : ''

    if (this.history.location.pathname === selectedActivityUrl) {
      this.displayActivityList()
    } else {
      this.history.goBack()
    }
  }

  public displayActivityList() {
    this._displayView(mobileRoutes.ACTIVITIES)
  }

  public displayUsersDirectoryView = () => {
    this._displayView(mobileRoutes.USERS_DIRECTORY)
  }

  public displayChatView = () => {
    this._displayView(mobileRoutes.CHAT)
  }

  public displayUserProfileView = ({ id }: User) => {
    this._displayView(mobileRoutes.USER_PROFILE.replace(':userId', id))
  }

  public displayLoginView = () => {
    this.showNavBar()
    this._displayView(mobileRoutes.LOGIN)
  }

  /**
   * @param {string} id - If present, will open the notification with the given value in its `entityId`
   */
  public displayNotifications = (id?: string) => {
    this.showNavBar()
    this._displayView(mobileRoutes.NOTIFICATIONS, { id })
  }

  public displayProjects = () => {
    this.showNavBar()
    this._displayView(mobileRoutes.PROJECTS)
  }

  public openDeliveryView() {
    this.hideNavBar()
    this.displayDeliveriesView()
  }

  public displayForms() {
    this._displayView(mobileRoutes.FORMS)
  }

  public displayQRCodes() {
    this._displayView(mobileRoutes.QR_CODES)
  }

  public displayDeliveriesView = (viewMode?: string) => {
    this._displayView(mobileRoutes.DELIVERIES, { viewMode })
  }

  public displayDeliveryDetailsView = (id: string) => {
    this.hideNavBar()
    this._displayView(
      mobileRoutes.DELIVERY_DETAILS.replace(
        getURLParam(mobileRoutes.DELIVERY_DETAILS),
        id,
      ),
    )
  }

  public displayAnnouncementView(id: string) {
    if (!id) {
      return
    }
    const route = mobileRoutes.ANNOUNCEMENT_DETAILS.replace(
      ':announcementId',
      id,
    )
    this._displayView(route)
  }

  public displayPhotos() {
    this._displayView(mobileRoutes.DOCUMENTS)
  }

  public displayNotificationDetails(notification: BaseNotification) {
    if (!notification) {
      return
    }

    const { entityId: id, activityObjectId, type } = notification

    switch (true) {
      case type === NotificationType.ScheduleUpdated:
        return

      case isFlagType(type):
        return this.displayFlagView({ id } as Flag)

      case isRFIType(type):
        return this.displayRfiView({ id } as Rfi)

      case isScheduleCommentType(type):
        return this.displayScheduleCommentView({ id } as ScheduleComment)

      case isDeliveryType(type):
        return this.displayDeliveryDetailsView(id)

      case isStatusUpdateType(type):
        return this.displayStatusUpdateMessagesView({ id } as StatusUpdate)

      case isPermitType(type):
        return this.displayFormView(id)

      case isAnnouncementType(type):
        return this.displayAnnouncementView(notification.entityId)

      default:
        this._displayView(
          mobileRoutes.ACTIVITY_DETAIL.replace(':code', activityObjectId),
        )
    }
  }

  public displayFlagView(flag: Flag) {
    if (!flag) {
      return
    }
    this.hideNavBar()
    this._displayView(mobileRoutes.FLAG.replace(':flagId', flag.id))
  }

  public displayRfiView(rfi: Rfi) {
    if (!rfi) {
      return
    }
    this.hideNavBar()
    this._displayView(mobileRoutes.RFI.replace(':rfiId', rfi.id))
  }

  public displayScheduleCommentView(scheduleComment: ScheduleComment) {
    if (!scheduleComment) {
      return
    }
    this.hideNavBar()
    this._displayView(
      mobileRoutes.SCHEDULE_COMMENT.replace(':scId', scheduleComment.id),
    )
  }

  public displayCategoryOfVarianceView(cov: CategoryOfVariance) {
    if (!cov) {
      return
    }
    this.hideNavBar()
    this._displayView(
      mobileRoutes.CATEGORY_OF_VARIANCE.replace(':covId', cov.id),
    )
  }

  public displaySafetyHazardView(sh: SafetyHazard) {
    if (!sh) {
      return
    }
    this.hideNavBar()
    this._displayView(mobileRoutes.SAFETY_HAZARD.replace(':shId', sh.id))
  }

  public displayPhotoView(photo: Photo) {
    if (!photo) {
      return
    }
    this.hideNavBar()
    this._displayView(mobileRoutes.PHOTO_DETAIL.replace(':photoId', photo.id))
  }

  public displayStatusUpdateMessagesView(statusUpdate: StatusUpdate) {
    if (!statusUpdate) {
      return
    }

    this.hideNavBar()
    this._displayView(
      mobileRoutes.STATUS_UPDATE_MESSAGES.replace(
        ':statusUpdateId',
        statusUpdate.id,
      ),
    )
  }

  public displayFormView(id: string) {
    if (!id) {
      return
    }

    this.hideNavBar()
    const route = mobileRoutes.FORM_VIEW.replace(':formId', id)
    this._displayView(route)
  }

  public displayBulkStatusUpdateView(
    activity: Activity,
    selectedCompany: string,
  ) {
    const activityToOpenView = activity || this.selectedActivity

    if (!activityToOpenView.code) {
      return
    }

    const { companies } = activityToOpenView
    const company = companies.includes(selectedCompany)
      ? selectedCompany
      : companies[0] || UNASSIGNED_FILTER_VALUE

    const route = mobileRoutes.BULK_STATUS_UPDATE.replace(
      ':activityCode',
      activityToOpenView.code,
    ).replace(':company', company)

    this.hideNavBar()
    this._displayView(route)
  }

  public displayActivityDetailsView(activityP6Code: string) {
    this._displayView(
      mobileRoutes.ACTIVITY_DETAIL.replace(':code', activityP6Code),
    )
  }

  @action.bound
  public openChat() {
    this.displayChatView()
  }

  public get defaultAvailablePage(): string {
    const {
      isDeliveriesDisabled,
      isLogisticsDisabled,
      isTrackerDisabled,
      activeProject: { defaultLandingPage = DefaultLandingPage.Home },
    } = this.state

    if (isDeliveryInfoPageRequested()) {
      return location.pathname
    }

    switch (true) {
      case defaultLandingPage === DefaultLandingPage.Home &&
        !isLogisticsDisabled:
        return commonRoutes.HOME
      case defaultLandingPage === DefaultLandingPage.Deliveries &&
        !isDeliveriesDisabled:
        return commonRoutes.DELIVERIES
      case defaultLandingPage === DefaultLandingPage.Tracker &&
        !isTrackerDisabled:
        return commonRoutes.ACTIVITIES
      default:
        return commonRoutes.NOTIFICATIONS
    }
  }
}
