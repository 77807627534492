import * as React from 'react'

import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'
import { Redirect, RouteComponentProps } from 'react-router'
import { withRouter } from 'react-router-dom'

import MobileActivityDetails from '~/client/src/mobile/components/ActivityDetails/MobileActivityDetails'
import MobilePhotoDetails from '~/client/src/mobile/components/PhotoDetails/MobilePhotoDetails'
import Sidebar from '~/client/src/mobile/components/Sidebar/Sidebar'
import routes from '~/client/src/mobile/constants/mobileRoutes'
import ActivitiesList from '~/client/src/mobile/views/ActivityList/ActivityListView'
import CategoryOfVarianceView from '~/client/src/mobile/views/CategoryOfVariance/CategoryOfVarianceView'
import DeliveryDetails from '~/client/src/mobile/views/DeliveryDetails/DeliveryDetailsView'
import DeliveryTicket from '~/client/src/mobile/views/DeliveryTicket/DeliveryTicket'
import Documents from '~/client/src/mobile/views/Documents/Documents'
import FlagView from '~/client/src/mobile/views/Flag/FlagView'
import Deliveries from '~/client/src/mobile/views/MobileDeliveriesView'
import Notifications from '~/client/src/mobile/views/Notifications/NotificationsList'
import PasswordResetViewBaseMobile from '~/client/src/mobile/views/PasswordResetViewBase/PasswordResetViewBaseMobile'
import { ProjectsView } from '~/client/src/mobile/views/Projects/Projects'
import RfiView from '~/client/src/mobile/views/Rfi/RfiView'
import SafetyHazardView from '~/client/src/mobile/views/SafetyHazard/SafetyHazardView'
import SavePasswordView from '~/client/src/mobile/views/SavePassword/SavePasswordView'
import ScheduleCommentView from '~/client/src/mobile/views/ScheduleComment/ScheduleCommentView'
import SendDeliveryTicketView from '~/client/src/mobile/views/SendDeliveryTicketView/SendDeliveryTicketView'
import StatusUpdateMessagesView from '~/client/src/mobile/views/StatusUpdateMessages/StatusUpdateMessagesView'
import LoginView from '~/client/src/mobile/views/login/Login'
import {
  Content,
  Footer,
  Header,
  View,
} from '~/client/src/shared/components/Layout'
import WrappedRoute, {
  IRoute,
} from '~/client/src/shared/components/WrappedRoute'

import BulkStatusUpdate from '../shared/components/BulkStatusUpdate/BulkStatusUpdate'
import { withErrorBoundary } from '../shared/components/ErrorBoundary'
import GlobalUserCardUnsafe from '../shared/components/GlobalUserCard/GlobalUserCard'
import { Loader } from '../shared/components/Loader'
import { getTransitionPath } from '../shared/constants/commonRoutes'
import Localization from '../shared/localization/LocalizationManager'
import RoutesStore from '../shared/stores/ui/Routes.store'
import InfoView from '../shared/views/InfoView/InfoView'
import GetTheAppBanner, {
  shouldShowBanner,
} from './components/GetTheAppBanner/GetTheAppBanner'
import MobileFullScreenPreview from './components/MobileFullscreenFilePreview/MobileFullscreenPreview'
import OneTimePopup from './components/OneTimePopup/OneTimePopup'
import SwitchWithSlide from './components/SwitchWithSlide/SwitchWithSlide'
import MobileInitialState from './stores/MobileInitialState'
import MobileCommonStore from './stores/ui/MobileCommon.store'
import AddTeammatesView from './views/AddTeammates/AddTeammates'
import AnnouncementView from './views/AnnouncementView/AnnouncementView'
import ChatView from './views/ChatView/ChatView'
import CompactForms from './views/Forms/CompactForms'
import LogisticsContainer from './views/Logistics/LogisticsContainer'
import NotificationsSettingsView from './views/NotificationsSettings/NotificationsSettingsView'
import PermitTypesView from './views/PermitTypesView/PermitTypesView'
import PermitView from './views/PermitView'
import QRCodeAccessStandalone from './views/QRCodes/QRCodeAccessStandalone'
import QRCodes from './views/QRCodes/QRCodes'
import SignUpView from './views/SignUpView'
import UserProfileView from './views/UserProfileView/UserProfileView'
import UsersDirectoryView from './views/UsersDirectoryView/UsersDirectoryView'

const GlobalUserCard = withErrorBoundary(GlobalUserCardUnsafe)

const mobileRoutes: IRoute[] = [
  {
    path: routes.LOGIN,
    exact: true,
    component: LoginView,
  },
  {
    path: routes.SIGN_UP,
    component: SignUpView,
  },
  {
    path: routes.FORMS,
    exact: true,
    component: CompactForms,
    isProtected: true,

    isFormsRelated: true,
  },
  {
    path: routes.FORM_VIEW,
    exact: false,
    component: PermitView,
    isProtected: true,

    isFormsRelated: true,
  },
  {
    path: routes.FORM_TYPES,
    component: PermitTypesView,
    isProtected: true,

    isFormsRelated: true,
  },
  {
    path: routes.HOME,
    exact: true,
    component: LogisticsContainer,
    isProtected: true,

    isLogisticsRelated: true,
  },
  {
    path: routes.ACTIVITIES,
    exact: true,
    component: ActivitiesList,
    isProtected: true,

    isTrackerRelated: true,
  },
  {
    path: routes.ACTIVITY_DETAIL,
    exact: true,
    component: MobileActivityDetails,
    isProtected: true,

    isTrackerRelated: true,
  },
  {
    path: routes.FLAG,
    exact: false,
    component: FlagView,
    isProtected: true,

    isTrackerRelated: true,
  },
  {
    path: routes.RFI,
    exact: false,
    component: RfiView,
    isProtected: true,

    isTrackerRelated: true,
  },
  {
    path: routes.SCHEDULE_COMMENT,
    exact: false,
    component: ScheduleCommentView,
    isProtected: true,

    isTrackerRelated: true,
  },
  {
    path: routes.CATEGORY_OF_VARIANCE,
    exact: false,
    component: CategoryOfVarianceView,
    isProtected: true,

    isPhotosRelated: true,
    isTrackerRelated: true,
  },
  {
    path: routes.SAFETY_HAZARD,
    exact: false,
    component: SafetyHazardView,
    isProtected: true,

    isTrackerRelated: true,
  },
  {
    path: routes.STATUS_UPDATE_MESSAGES,
    exact: false,
    component: StatusUpdateMessagesView,
    isProtected: true,

    isTrackerRelated: true,
  },
  {
    path: routes.BULK_STATUS_UPDATE,
    exact: false,
    component: BulkStatusUpdate,
    isProtected: true,

    isTrackerRelated: true,
  },
  {
    path: routes.NOTIFICATIONS,
    exact: false,
    component: Notifications,
    isProtected: true,
  },
  {
    path: routes.ANNOUNCEMENT_DETAILS,
    exact: false,
    component: AnnouncementView,
    isProtected: true,
  },
  {
    path: routes.DELIVERIES,
    exact: true,
    component: Deliveries,
    isProtected: true,

    isDeliveriesRelated: true,
  },
  {
    path: routes.DELIVERY_DETAILS,
    exact: false,
    component: DeliveryDetails,
    unprotectedAltComponent: DeliveryTicket,
    isProtected: true,

    isDeliveriesRelated: true,
  },
  {
    path: routes.DOCUMENTS,
    exact: false,
    component: Documents,
    isProtected: true,
  },
  {
    path: routes.PHOTO_DETAIL,
    exact: false,
    component: MobilePhotoDetails,
    isProtected: true,
  },
  {
    path: routes.PROJECTS,
    exact: false,
    component: ProjectsView,
    isPhotosRelated: true,
  },
  {
    path: routes.SAVE_PASSWORD,
    exact: false,
    component: SavePasswordView,
    isProtected: true,
  },
  {
    path: routes.RESET_PASSWORD,
    exact: false,
    component: PasswordResetViewBaseMobile,
  },
  {
    path: routes.USERS_DIRECTORY,
    exact: true,
    component: UsersDirectoryView,
    isProtected: true,
  },
  {
    path: routes.QR_CODES,
    exact: true,
    component: QRCodes,
    isProtected: true,
    isQRCodeRelated: true,
  },
  {
    path: routes.QR_CODE_ACCESS,
    exact: false,
    component: QRCodeAccessStandalone,
    isProtected: true,
    isQRCodeRelated: true,
    isAdminOnly: true,
  },
  {
    path: routes.NOTIFICATION_SETTINGS,
    exact: false,
    component: NotificationsSettingsView,
    isProtected: true,
  },
  {
    path: routes.USER_PROFILE,
    exact: false,
    component: UserProfileView,
    isProtected: true,
  },
  {
    path: routes.SEND_SMS_DELIVERY_TICKET,
    exact: false,
    component: SendDeliveryTicketView,
    isDeliveriesRelated: true,
  },
  {
    path: routes.INFO,
    exact: false,
    component: InfoView,
  },
  {
    path: routes.CHAT,
    component: ChatView,
    isProtected: true,
  },
  {
    path: routes.ADD_TEAMMATE,
    component: AddTeammatesView,
    isProtected: true,
    isQRCodeRelated: true,
  },
]

interface IMobileRootViewProps {
  state?: MobileInitialState
  common?: MobileCommonStore
}

@inject('common', 'state')
@observer
class MobileRootView extends React.Component<
  IMobileRootViewProps & RouteComponentProps
> {
  private readonly store: RoutesStore = null
  @observable private isBannerHidden = !shouldShowBanner()

  public constructor(props: IMobileRootViewProps & RouteComponentProps) {
    super(props)
    this.store = new RoutesStore(props.state)
    props.common.initHistory(props.history)
  }

  public render() {
    return <main className="full-height">{this.content}</main>
  }

  public get content(): JSX.Element {
    const { isFullHeight, isSidebarOpen, toggleSidebar } = this.props.common

    if (this.store.areProjectTypesLoading) {
      return (
        <View>
          <Content scrollable>
            <div className="absolute-block" style={{ top: '50%' }}>
              <div className="col x-center y-center">
                <Loader size={25} />
                <span>{Localization.translator.loading}</span>
              </div>
            </div>
          </Content>
        </View>
      )
    }

    const isProjectSelected = !!this.props.state.activeOrInitProjectCode

    return (
      <View>
        {isProjectSelected && (
          <>
            <GetTheAppBanner
              hidden={this.isBannerHidden}
              onDismiss={this.handleBannerDismiss}
            />
            <OneTimePopup />
          </>
        )}
        <Header>
          <Sidebar />
          <div
            onClick={toggleSidebar}
            className={classList({
              dimmer: true,
              shown: isSidebarOpen,
            })}
          />
        </Header>
        <div
          className={classList({
            'layout-view-root col': true,
            'displayed-header': !isFullHeight,
            'full-height': isFullHeight,
          })}
        >
          <Content
            scrollable
            className={toggleClass('with-banner', !this.isBannerHidden)}
          >
            <MobileFullScreenPreview />
            <MobileRoutes />
          </Content>
        </div>
        <Footer />
      </View>
    )
  }

  @action.bound
  private handleBannerDismiss() {
    this.isBannerHidden = true
  }
}

export default withRouter(MobileRootView)

interface IMobileRoutes {
  state?: MobileInitialState
  common?: MobileCommonStore
}

@inject('state', 'common')
@observer
class MobileRoutes extends React.Component<IMobileRoutes> {
  public get routes() {
    const {
      isDeliveriesDisabled,
      isTrackerDisabled,
      isLogisticsDisabled,
      isFormsDisabled,
      userActiveProjectSettings,
    } = this.props.state

    return mobileRoutes.filter(
      route =>
        (!isDeliveriesDisabled || !route.isDeliveriesRelated) &&
        (!isTrackerDisabled || !route.isTrackerRelated) &&
        (userActiveProjectSettings?.hasScanAccess || !route.isQRCodeRelated) &&
        (userActiveProjectSettings?.isSuperUser || !route.isAdminOnly) &&
        (!isLogisticsDisabled || !route.isLogisticsRelated) &&
        (!isFormsDisabled || !route.isFormsRelated) &&
        (!isTrackerDisabled || !route.isTrackerRelated),
    )
  }

  public render() {
    const { state } = this.props

    let redirectToUrl = '/'
    if (!state.isLogisticsDisabled) {
      redirectToUrl = routes.HOME
    } else if (!state.isDeliveriesDisabled) {
      redirectToUrl = routes.DELIVERIES
    } else if (!state.isTrackerDisabled) {
      redirectToUrl = routes.ACTIVITIES
    }

    return (
      <>
        {state.idOfUserDisplayedOnGlobalCard && (
          <GlobalUserCard userId={state.idOfUserDisplayedOnGlobalCard} />
        )}
        <SwitchWithSlide>
          {this.routes.map(route => (
            <WrappedRoute
              key={route.path}
              {...route}
              defaultViewToDisplay="/"
            />
          ))}
          <Redirect
            to={getTransitionPath(
              redirectToUrl,
              this.props.state.activeOrInitProjectCode,
            )}
          />
        </SwitchWithSlide>
      </>
    )
  }
}
