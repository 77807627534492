import * as React from 'react'

import { inject, observer } from 'mobx-react'

import MobileFileInput from '~/client/src/mobile/components/FileInput/MobileFileInput'
import DeliveryDetails from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails'
import DeliveryDetailsStore from '~/client/src/shared/components/DeliveryDetails/DeliveryDetails.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import { NEW_DELIVERY_PATH_KEYWORD } from '~/client/src/shared/utils/usefulStrings'

interface IProps {
  deliveryId: string
  projectId: string
  onBackClick(): void
  onCreationCallback(deliveryId: string): void
  onMount(): void

  deliveryDetailsStore?: DeliveryDetailsStore
  deliveriesStore?: DeliveriesStore
}

@inject('deliveryDetailsStore', 'deliveriesStore')
@observer
export default class DeliveryDetailsWrapper extends React.Component<IProps> {
  public componentDidMount() {
    this.prepareView(this.props.deliveryId)
  }

  public componentDidUpdate(prevProps: IProps) {
    const { deliveryId } = this.props
    if (prevProps.deliveryId !== deliveryId) {
      this.prepareView(deliveryId)
    }
  }

  public render() {
    const { deliveryId, projectId, onBackClick, onCreationCallback, onMount } =
      this.props

    return (
      <DeliveryDetails
        backClicked={onBackClick}
        FileInputType={MobileFileInput}
        onDidMount={onMount}
        initProjectId={projectId}
        displayedDeliveryId={deliveryId}
        onCreationCb={onCreationCallback}
        isMobileMode={true}
      />
    )
  }

  private prepareView(deliveryIdFromUrl: string) {
    if (!deliveryIdFromUrl) {
      return
    }

    const { deliveriesStore, deliveryDetailsStore } = this.props
    if (
      deliveryIdFromUrl === NEW_DELIVERY_PATH_KEYWORD &&
      deliveryDetailsStore.isDefaultModeActive
    ) {
      return deliveryDetailsStore.setNewDeliveryMode()
    }

    const delivery = deliveriesStore.byId.get(deliveryIdFromUrl)
    if (delivery) {
      deliveryDetailsStore.setChangeMode(delivery)
    }
  }
}
