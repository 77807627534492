import { computed } from 'mobx'

import {
  ActivityStatus,
  DeliveryStatus,
  SitePermitStatus,
} from '~/client/graph'
import MobileEventsStore from '~/client/src/mobile/stores/EventStore/MobileEvents.store'
import { UNASSIGNED_FILTER_OPTION } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import {
  LogisticsFilterType,
  formsFilterTypes,
  getLogisticsFilterTypeCaption,
  hubFilterTypes,
} from '~/client/src/shared/enums/LogisticsFilterType'
import { LogisticItemApp } from '~/client/src/shared/models/ILogisticItem'
import BaseLogisticsFilterStore, {
  ISourceMap,
} from '~/client/src/shared/stores/BaseLogisticsFilter.store'
import LogisticsFilterStore from '~/client/src/shared/stores/LogisticsFilter.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { enumToList } from '~/client/src/shared/utils/converters'
import { LOCATION_SEPARATOR } from '~/client/src/shared/utils/usefulStrings'

import MobileLogisticsStore from './MobileLogistics.store'
import MobileLogisticsListStore from './MobileLogisticsList.store'

const EXCLUDED_STATUSES = [DeliveryStatus.Deleted]

export default class MobileLogisticsFilterStore extends LogisticsFilterStore {
  public constructor(
    protected readonly eventsStore: MobileEventsStore,
    protected readonly logisticsStore: MobileLogisticsStore,
    protected readonly logisticsListStore: MobileLogisticsListStore,
    protected readonly onShowChanged: (
      isShown: boolean,
      filterType: string,
    ) => void,
    protected readonly locationAttributesStore: LocationAttributesStore,
    protected readonly permitTypesStore: PermitTypesStore,
    protected readonly tagsStore: TagsStore,
    protected readonly isPermitsOnly?: boolean,
  ) {
    super(
      eventsStore,
      logisticsStore,
      onShowChanged,
      locationAttributesStore,
      permitTypesStore,
      tagsStore,
      isPermitsOnly,
    )
  }

  protected get availableInstances() {
    return this.logisticsStore.logisticItemsInPeriodInterval
  }

  @computed
  public get filterStoresByTypeMap(): {
    [filterType: string]: BaseLogisticsFilterStore
  } {
    const { appState } = this.eventsStore
    const map: { [filterType: string]: BaseLogisticsFilterStore } = {}

    this.filterTypes.forEach(filterType => {
      map[filterType] = new BaseLogisticsFilterStore(
        filterType,
        appState,
        this.sourceMapByFilterTypeMap[filterType],
        this.logisticsListStore,
        this.onShowChanged,
        this.fieldsMap(),
        getLogisticsFilterTypeCaption,
        this.getOptionName,
        true,
      )
    })

    return map
  }

  @computed
  protected get sourceMapByFilterTypeMap(): {
    [filterType: string]: ISourceMap
  } {
    const maps = this.filterTypes.reduce((acc, filterType) => {
      acc[filterType] = this.getDefaultSourceMapByType(filterType)
      return acc
    }, {})

    this.availableInstances.forEach(instance => {
      this.filterTypes.forEach(filterType => {
        const map = maps[filterType]
        let optionIds = []

        switch (filterType) {
          case LogisticsFilterType.Status:
            optionIds = [instance.status || UNASSIGNED_FILTER_OPTION]
            break
          case LogisticsFilterType.Location:
            optionIds = this.getLocationValues(instance.locations)
            break
          case LogisticsFilterType.Equipment:
            optionIds = this.getLocationValues(instance.equipment)
            break
          case LogisticsFilterType.Company:
            optionIds = instance.companyIds?.length
              ? instance.companyIds
              : [UNASSIGNED_FILTER_OPTION]
            break
          case LogisticsFilterType.App:
            optionIds = [instance.app || UNASSIGNED_FILTER_OPTION]
            break
          case LogisticsFilterType.ResponsibleContact:
            optionIds = instance.responsibleContactIds?.length
              ? instance.responsibleContactIds
              : [UNASSIGNED_FILTER_OPTION]
            break
          case LogisticsFilterType.Type:
            if (instance.app === LogisticItemApp.FORM) {
              const form = this.logisticsStore.getFormById(instance.entityId)
              optionIds = [
                form?.getTypeOfPermitType(this.permitTypesStore) ||
                  UNASSIGNED_FILTER_OPTION,
              ]
            } else {
              optionIds = []
            }
            break
        }

        optionIds.forEach(optionId => {
          if (!map[optionId]) {
            optionId = UNASSIGNED_FILTER_OPTION
          }

          map[optionId]?.push(instance.id)
        })
      })
    })
    return maps
  }

  protected getDefaultSourceMapByType(type: LogisticsFilterType) {
    let sourceList: string[] = []

    switch (type) {
      case LogisticsFilterType.Status:
        sourceList = [...enumToList(SitePermitStatus)]
        if (!this.isPermitsOnly) {
          sourceList.push(
            ...enumToList(DeliveryStatus).filter(
              s => !EXCLUDED_STATUSES.includes(s),
            ),
            ...enumToList(ActivityStatus),
          )
        }
        sourceList.push(UNASSIGNED_FILTER_OPTION)
        break
      case LogisticsFilterType.App:
        sourceList = [
          LogisticItemApp.FORM,
          LogisticItemApp.SCHEDULE,
          LogisticItemApp.ANNOUNCEMENT,
          LogisticItemApp.DELIVERY,
        ]
        break
      case LogisticsFilterType.ResponsibleContact:
        sourceList = this.availableInstances.reduce((acc, instance) => {
          instance.responsibleContactIds?.forEach(id => {
            if (!acc.includes(id)) {
              acc.push(id)
            }
          })
          return acc
        }, [])
        break
      case LogisticsFilterType.Location:
        sourceList = [
          ...this.locationAttributesStore.allLocationsWithoutEquipment.map(
            l => `${l.type}${LOCATION_SEPARATOR}${l.id}`,
          ),
          UNASSIGNED_FILTER_OPTION,
        ]
        break
      case LogisticsFilterType.Equipment:
        sourceList = [
          ...this.locationAttributesStore.offloadingEquipmentsStore.list.map(
            l => `${l.type}${LOCATION_SEPARATOR}${l.id}`,
          ),
          UNASSIGNED_FILTER_OPTION,
        ]
        break
      case LogisticsFilterType.Company:
        sourceList = this.availableInstances.reduce((acc, instance) => {
          instance.companyIds?.forEach(id => {
            if (!acc.includes(id)) {
              acc.push(id)
            }
          })
          return acc
        }, [])
        break
      case LogisticsFilterType.Type:
        sourceList = this.availableInstances.map(instance => {
          return instance.type || UNASSIGNED_FILTER_OPTION
        })
        break
    }
    return sourceList.reduce((acc, optionId) => {
      acc[optionId] = []
      return acc
    }, {})
  }

  protected fieldsMap() {
    const { logisticsFilters, formsFilters } = this.eventsStore.appState
    return this.isPermitsOnly
      ? formsFilters.fieldsMap
      : logisticsFilters.fieldsMap
  }

  protected get filterTypes(): LogisticsFilterType[] {
    return this.isPermitsOnly ? formsFilterTypes : hubFilterTypes
  }
}
