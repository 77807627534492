import { action, observable } from 'mobx'

import { IFilters } from '~/client/src/shared/stores/InitialState'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import BaseLogisticsMapStore from '~/client/src/shared/stores/ui/BaseLogisticsMap.store'

import MobileLogisticsStore from './MobileLogistics.store'

export default class MobileLogisticsMapStore extends BaseLogisticsMapStore {
  @observable public isFiltersModalOpen: boolean = false

  public constructor(
    store: MobileLogisticsStore,
    companiesStore: CompaniesStore,
    deliveriesStore: DeliveriesStore,
    sitePermitsStore: SitePermitsStore,
    activitiesStore: ActivitiesStore,
  ) {
    super(
      store,
      companiesStore,
      deliveriesStore,
      sitePermitsStore,
      activitiesStore,
    )
  }

  @action.bound
  public toggleFilters() {
    this.isFiltersModalOpen = !this.isFiltersModalOpen
  }

  protected get filter(): IFilters {
    return this.store.filters
  }
}
