import * as React from 'react'

import { inject } from 'mobx-react'

import NotificationCountLabelComponent from '~/client/src/mobile/components/NotificationCountLabel'
import MobileCommonStore from '~/client/src/mobile/stores/ui/MobileCommon.store'
import CompactDeliveriesView from '~/client/src/shared/components/Deliveries/DeliveriesView'

import BottomNavigationNavBar from '../components/BottomNavigationNavBar/BottomNavigationNavBar'
import MobileFileInput from '../components/FileInput/MobileFileInput'
import MobileInitialState from '../stores/MobileInitialState'

interface IProps {
  common: MobileCommonStore
  state: MobileInitialState
}

@inject('common', 'state')
export default class MobileDeliveriesView extends React.Component<IProps> {
  public componentDidMount() {
    this.props.common.hideNavBar()
  }

  public render() {
    return (
      <CompactDeliveriesView
        dateSelectorPostfix={this.dateSelectorPostfix}
        shouldUseSavedFilters={false}
        FileInputType={MobileFileInput}
        renderFooter={this.renderFooter}
        isCompactMode={true}
      />
    )
  }

  private get dateSelectorPostfix() {
    return (
      <div className="no-grow ml20">
        <NotificationCountLabelComponent />
      </div>
    )
  }

  private renderFooter = (onCreateClicked?: () => void) => {
    const { userActiveProjectSettings, delivery } = this.props.state

    const { isViewWithHighlightedDeliveryMode, isActivityMode } =
      delivery.compactView

    const shouldShowBottomNavBar =
      !isViewWithHighlightedDeliveryMode &&
      !userActiveProjectSettings?.isPresentationUser &&
      !isActivityMode

    return (
      shouldShowBottomNavBar && (
        <BottomNavigationNavBar onCreateClicked={onCreateClicked} />
      )
    )
  }
}
