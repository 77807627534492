import * as React from 'react'

import { observer } from 'mobx-react'

import { DeliveryStatus, SitePermitStatus } from '~/client/graph'
import ActivityStatusIndicator from '~/client/src/shared/components/ActivityItemContentWithStatusUpdate/componets/ActivityStatusIndicator'
import DeliveryStatusLabel from '~/client/src/shared/components/DeliveryStatusLabel/DeliveryStatusLabel'
import * as Icons from '~/client/src/shared/components/Icons'
import {
  ILWFCRow,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import WorkflowCardStatus from '~/client/src/shared/components/WorkflowCard/Status'
import ILogisticItem, {
  LogisticItemApp,
} from '~/client/src/shared/models/ILogisticItem'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { logisticsDataKeys } from '~/client/src/shared/types/LogisticsDataKeys'
import { NOOP } from '~/client/src/shared/utils/noop'

import LogisticsListCard from '../LogisticsListCard/LogisticsListCard'

import './LogisticsListTable.scss'

interface IProps {
  rows: ILWFCRow[]
  groupingKey: string
  projectDateStore: ProjectDateStore
  companiesStore: CompaniesStore
  activitiesStore: ActivitiesStore
  permitTypesStore: PermitTypesStore
  collapsedCategories?: Map<string, boolean>
  onCellClick?: (rowData: LWFCRowData) => void
  onCategoryCollapsingToggle?: (categoryId: string) => void
}

@observer
export default class LogisticsListTable extends React.Component<IProps> {
  public static defaultProps = {
    onCellClick: NOOP,
    onCategoryCollapsingToggle: NOOP,
  }

  private renderStatus = (item: ILogisticItem): JSX.Element => {
    const { app, status, workflowStepLevel, isStatusLate, id } = item
    switch (app) {
      case LogisticItemApp.FORM:
        return (
          <WorkflowCardStatus
            status={status as SitePermitStatus}
            workflowStepLevel={workflowStepLevel}
            isLate={isStatusLate}
            alignRight={true}
          />
        )
      case LogisticItemApp.DELIVERY:
        return <DeliveryStatusLabel status={status as DeliveryStatus} />
      case LogisticItemApp.SCHEDULE:
        return (
          <ActivityStatusIndicator
            activity={this.props.activitiesStore.byId.get(id)}
            containerClassName="y-center col w-fit-content"
          />
        )
      default:
        return <div className="text large">{status}</div>
    }
  }

  private renderDates = (item: ILogisticItem) => {
    const { startDate, endDate } = item
    const { getTimeToDisplay, getMonthDayAndTimeToDisplay, isSameDay } =
      this.props.projectDateStore
    const startDateStr = getMonthDayAndTimeToDisplay(startDate)
    let endDateStr = getMonthDayAndTimeToDisplay(endDate)
    if (isSameDay(startDate, endDate)) {
      endDateStr = getTimeToDisplay(endDate)
    }

    return (
      <>
        <span>{startDateStr}</span>
        <span className="px5">-</span>
        <span>{endDateStr}</span>
      </>
    )
  }

  public render() {
    const {
      rows,
      companiesStore,
      permitTypesStore,
      collapsedCategories,
      onCategoryCollapsingToggle,
      onCellClick,
    } = this.props

    return (
      <div className="logistics-list-table relative full-height">
        {rows.map((r, idx) => {
          if (r.data?.[logisticsDataKeys.ID]) {
            const item = r.data[logisticsDataKeys.ID] as ILogisticItem
            return (
              <LogisticsListCard
                key={item.id + idx}
                item={item}
                getCompanyNameById={companiesStore.getCompanyNameById}
                getTypeName={permitTypesStore.getLastUpdatedTypeByType}
                renderDates={this.renderDates}
                onClick={onCellClick.bind(this, r.data)}
                renderStatus={this.renderStatus}
              />
            )
          }
          if (r.category) {
            return (
              <div
                key={r.category.categoryId}
                className="row y-center section-header px12 py5 bb-light-grey"
                onClick={onCategoryCollapsingToggle.bind(
                  this,
                  r.category.categoryId,
                )}
              >
                <div className="arrow-icon">
                  {collapsedCategories.get(r.category.categoryId) ? (
                    <Icons.RightArrow />
                  ) : (
                    <Icons.DownArrow />
                  )}
                </div>
                <div
                  className="text bold small-header ellipsis"
                  title={r.category.categoryLabel}
                >
                  {r.category.categoryLabel}
                </div>
                <div className="text bold end w-fit-content">
                  {r.category.idsToSelect.length}
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }
}
