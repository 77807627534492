import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import SectionIcon from '../../SectionIcon/SectionIcon'
import * as SidebarBadge from './SidebarBadge/SidebarBadge'
import {
  SidebarBadgeColumn,
  SidebarIconColumn,
  SidebarTitleColumn,
} from './SidebarSections/SidebarSections'

interface ISidebarItem {
  hideIcon?: boolean
  badge?: React.ReactText
  bold?: boolean
  route: string
  text: string
  isLast: boolean
  isDisabled?: boolean
  onClickHandler?: (isDisabled: boolean, route: string) => void
}

@observer
export default class SidebarItem extends React.Component<ISidebarItem> {
  public render() {
    const {
      hideIcon = false,
      badge = null,
      bold = false,
      route,
      text,
      isDisabled,
      isLast,
    } = this.props

    return (
      <div
        onClick={this.onClick}
        className={classList({
          'bg-white bt-cool-grey h48 row': true,
          'indication-click': !isDisabled,
          'unclickable-element': isDisabled,
          'bb-cool-grey': isLast,
        })}
      >
        <SidebarIconColumn isDisabled={isDisabled}>
          {!hideIcon && <SectionIcon route={route} />}
        </SidebarIconColumn>
        <SidebarTitleColumn
          text={text}
          isDisabled={isDisabled}
          bold={bold}
          isBadgeShown={!!badge}
        />
        <SidebarBadgeColumn isDisabled={isDisabled}>
          {badge && <SidebarBadge.Badge content={badge} />}
        </SidebarBadgeColumn>
      </div>
    )
  }

  private onClick = (evt: React.SyntheticEvent<HTMLDivElement>) => {
    evt.stopPropagation()

    const { isDisabled, route } = this.props

    this.props.onClickHandler(isDisabled, route)
  }
}
