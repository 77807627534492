import * as React from 'react'

import { Icon, IconSize } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import CalendarEvent from '~/client/src/shared/models/CalendarEvent'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import CalendarDayViewStore from '../../CalendarDayView.store'
import FormMultiDayLabel from './FormMultiDayEventLabel'

import './MultiDayEventsBar.scss'

const COLLAPSE_THRESHOLD = 4

interface IProps {
  viewingDate: Date
  setViewingDate: (date: Date) => void
  events: CalendarEvent[]
  calendarStore: CalendarDayViewStore

  projectDateStore?: ProjectDateStore
  onEventClicked: (event: CalendarEvent) => void
}

@inject('projectDateStore')
@observer
export default class MultiDayEventsBar extends React.Component<IProps> {
  @observable private isExpanded: boolean = false

  public render() {
    const {
      viewingDate,
      calendarStore,
      projectDateStore: { isSameDay },
    } = this.props

    const allEvents = calendarStore.getActiveMultiDayEvents(this.props.events)

    const events = this.isExpanded
      ? allEvents
      : allEvents.slice(0, COLLAPSE_THRESHOLD)

    if (!events.length) return null

    return (
      <div className="multi-day-events-container col y-start bg-white pt5">
        <div className="overflow-y-auto">
          {events.map((event, idx) => {
            const isStartDate = isSameDay(event.startDate, viewingDate)
            const isEndDate = isSameDay(event.endDate, viewingDate)

            return (
              <div
                key={event.dataId || idx}
                onClick={() => this.props.onEventClicked(event)}
                style={event.styles}
                className={classList({
                  'multi-day-event pl5 mb2 text light row nowrap overflow-hidden':
                    true,
                  brls4: isStartDate,
                  brrs4: isEndDate,
                  'extends-left': !isStartDate,
                  'extends-right': !isEndDate,
                })}
              >
                <FormMultiDayLabel event={event} iconClassName="w18" />
              </div>
            )
          })}
        </div>

        {this.renderFooter(allEvents.length)}
      </div>
    )
  }

  private renderFooter(eventsCount: number): JSX.Element {
    const shouldShowCollapse = eventsCount > COLLAPSE_THRESHOLD

    if (!shouldShowCollapse) return null

    const hiddenCount = eventsCount - COLLAPSE_THRESHOLD

    return (
      <div
        className="collapse-container pointer mt5"
        onClick={this.toggleExpand}
      >
        <Icon
          icon={this.isExpanded ? IconNames.CHEVRON_UP : IconNames.CHEVRON_DOWN}
          size={IconSize.STANDARD}
          className="mr5"
        />
        {this.isExpanded
          ? Localization.translator.showLess
          : Localization.translator.showXMore(hiddenCount)}
      </div>
    )
  }

  @action.bound
  private toggleExpand() {
    this.isExpanded = !this.isExpanded
  }
}
