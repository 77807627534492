import React from 'react'

import { inject, observer } from 'mobx-react'

import CompactGroupByPicker from '~/client/src/shared/components/CompactGroupByPicker/CompactGroupByFilter'
import * as Icons from '~/client/src/shared/components/Icons'
import { getLogisticsGroupingOptionTranslatorKey } from '~/client/src/shared/enums/LogisticsGroupingOption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import AnnouncementsStore from '~/client/src/shared/stores/domain/Announcements.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import MobileInitialState from '../../../../stores/MobileInitialState'
import MobileLogisticsStore from '../../stores/MobileLogistics.store'
import MobileLogisticsFilterStore from '../../stores/MobileLogisticsFilter.store'
import MobileLogisticsGroupingOptionsStore from '../../stores/MobileLogisticsGroupingOptions.store'
import MobileLogisticsListStore from '../../stores/MobileLogisticsList.store'
import CompactLogisticsFilter from '../CompactLogsiticsFilter/CompactLogisticsFilter'
import LogisticsFilters from '../LogisticsFilters/LogisticsFilters'
import LogisticsListTable from '../LogisticsListTable/LogisticsListTable'

import './LogisticsList.scss'

export interface IProps {
  viewingDate: Date
  state?: MobileInitialState
  activitiesStore?: ActivitiesStore
  deliveriesStore?: DeliveriesStore
  sitePermitsStore?: SitePermitsStore
  permitTypesStore?: PermitTypesStore
  announcementsStore?: AnnouncementsStore
  companiesStore?: CompaniesStore
  projectDateStore?: ProjectDateStore
  store: MobileLogisticsStore
  logisticsListStore: MobileLogisticsListStore
  logisticsFilterStore: MobileLogisticsFilterStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
  isPermitOnly?: boolean
  isListOnly?: boolean
  tagsStore?: TagsStore
}

@inject(
  'state',
  'activitiesStore',
  'deliveriesStore',
  'sitePermitsStore',
  'permitTypesStore',
  'announcementsStore',
  'deliveryAssignmentsStore',
  'deliveryFollowingsStore',
  'activityFollowingsStore',
  'activityAssignmentsStore',
  'companiesStore',
  'userProjectsStore',
  'projectDateStore',
  'projectMembersStore',
  'tagsStore',
)
@observer
export default class MobileLogisticsList extends React.Component<IProps> {
  private readonly logisticsGroupingOptionsStore: MobileLogisticsGroupingOptionsStore =
    null

  public constructor(props: IProps) {
    super(props)

    this.logisticsGroupingOptionsStore =
      new MobileLogisticsGroupingOptionsStore(
        this.props.store.filters,
        getLogisticsGroupingOptionTranslatorKey,
        this.props.isPermitOnly,
      )
  }
  public render() {
    const { logisticsFilterStore, logisticsListStore } = this.props
    const {
      collapsedCategories,
      groupingKey,
      toggleCategoryCollapsing,
      onRowClick,
      rows,
      toggleFilters,
      toggleGroupBy,
      isFiltersModalOpen,
      isGroupByFilterShown,
    } = logisticsListStore
    return (
      <div className="logistics-list bg-white full-height">
        <div className="body-header row full-width sticky z-index-5 bg-white">
          <LogisticsFilters
            companiesStore={this.props.companiesStore}
            logisticsStore={this.props.store}
            toggleFilters={this.props.logisticsListStore.toggleFilters}
            isPermitOnly={this.props.isPermitOnly}
            logisticsFilterStore={logisticsFilterStore}
            state={this.props.state}
            userProjectsStore={this.props.userProjectsStore}
            selectedOptionsCount={logisticsFilterStore.selectedOptionsCount}
          />
        </div>
        <div className="workflows-summary row py5 sticky z-index-5 bb-light-grey bg-white">
          <div
            className="row"
            onClick={() => this.expendOrCollapseCategories()}
          >
            <Icons.NavigationReveryArrows className="row workflow-icon w-fit-content mr10 ml15" />
            <div className="text large line-extra-large">
              {this.totalWorkflowCount} {Localization.translator.workflows}
            </div>
          </div>
          <div className="row">
            <Icons.ThreeDots
              className="workflow-icon row mr15 no-grow flex-pull-right"
              onClick={() => toggleGroupBy()}
            />
          </div>
        </div>
        <div className="col items-list">
          <LogisticsListTable
            rows={rows}
            groupingKey={groupingKey}
            collapsedCategories={collapsedCategories}
            companiesStore={this.props.companiesStore}
            projectDateStore={this.props.projectDateStore}
            activitiesStore={this.props.activitiesStore}
            permitTypesStore={this.props.permitTypesStore}
            onCategoryCollapsingToggle={toggleCategoryCollapsing}
            onCellClick={onRowClick}
          />
        </div>
        <CompactLogisticsFilter
          store={this.props.logisticsFilterStore}
          onClose={toggleFilters}
          isShown={isFiltersModalOpen}
          selectedOptionsCount={logisticsFilterStore.selectedOptionsCount}
          companiesStore={this.props.companiesStore}
          projectMembersStore={this.props.projectMembersStore}
          tagsStore={this.props.tagsStore}
        />
        <CompactGroupByPicker
          isShown={isGroupByFilterShown}
          selectedMode={groupingKey}
          onHide={toggleGroupBy}
          onGroupByChange={this.onGroupByChange}
          groupByOptions={this.logisticsGroupingOptionsStore.groupByOptions}
        />
      </div>
    )
  }

  private onGroupByChange = (bandType: any) => {
    const { toggleGroupBy, selectGroupBy } = this.props.logisticsListStore
    selectGroupBy(bandType)
    toggleGroupBy()
  }

  private get totalWorkflowCount() {
    return this.categoryRows.length
      ? this.categoryRows
          .map(r => r.category.idsToSelect.length)
          .reduce((p, c) => p + c)
      : 0
  }

  private get categoryRows() {
    return this.props.logisticsListStore.rows.filter(r => !!r.category)
  }

  private expendOrCollapseCategories() {
    const { collapseAllCategories, expendAllCategories } =
      this.props.logisticsListStore
    this.isAllCategoriesCollapsed()
      ? expendAllCategories()
      : collapseAllCategories()
  }

  private isAllCategoriesCollapsed() {
    const { collapsedCategories } = this.props.logisticsListStore
    return this.categoryRows.find(
      c =>
        !collapsedCategories.has(c.category.categoryId) ||
        collapsedCategories.get(c.category.categoryId),
    )
  }
}
