import * as React from 'react'

import { ISidebarItem } from '../Sidebar.store'
import SidebarItem from './SidebarItem'

export default function SidebarItems(props: {
  items: ISidebarItem[]
  onClickHandler: (isDisabled: boolean, route: string) => void
}) {
  const { items, onClickHandler } = props

  return (
    <>
      {items.map((item, index) => {
        return (
          <SidebarItem
            key={item.id + '-item'}
            text={item.name}
            route={item.route}
            isDisabled={item.isDisabled}
            onClickHandler={onClickHandler}
            isLast={index === items.length - 1}
          />
        )
      })}
    </>
  )
}
