import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Avatar, {
  AvatarSize,
} from '~/client/src/shared/components/Avatar/Avatar'
import CompactSuperFilter from '~/client/src/shared/components/Filters/CompactSuperFilter'
import SuperFilterHandleButton from '~/client/src/shared/components/Filters/SuperFilter/components/SuperFilterHandleButton/SuperFilterHandleButton'
import * as Icons from '~/client/src/shared/components/Icons'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import AvatarType from '~/client/src/shared/enums/AvatarType'
import {
  LogisticsFilterType,
  getLogisticsFilterTypeCaption,
} from '~/client/src/shared/enums/LogisticsFilterType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { LogisticItemApp } from '~/client/src/shared/models/ILogisticItem'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import BaseLogisticsFilterStore from '~/client/src/shared/stores/BaseLogisticsFilter.store'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import LogisticsFilterStore from '~/client/src/shared/stores/LogisticsFilter.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'

import './CompactLogisticsFilter.scss'

const clearXFilters = 'Clear {x} filters'

interface IProps {
  store: LogisticsFilterStore
  onClose: () => void
  onApply?: () => void
  isShown: boolean
  selectedOptionsCount: number
  companiesStore: CompaniesStore
  projectMembersStore: ProjectMembersStore
  eventsStore?: EventsStore
  tagsStore: TagsStore
}

const UNKNOWN_USER_INITIALS = 'UU'

const STORE_TO_ICON_MAP = {
  [LogisticsFilterType.Location]: (
    <Icons.Location className="no-grow mr10 item-icon" />
  ),
  [LogisticsFilterType.Equipment]: (
    <TagIcon.Equipment className="no-grow mr10 item-icon" />
  ),
  [LogisticsFilterType.Company]: (
    <Icons.CompanyCompact className="no-grow mr10 item-icon" />
  ),
  [LogisticsFilterType.Status]: (
    <Icon icon={IconNames.TICK} className="no-grow mr10 item-icon" />
  ),
  [LogisticsFilterType.App]: (
    <Icons.LogoDark className="no-grow mr10 item-icon" />
  ),
  [LogisticsFilterType.ResponsibleContact]: (
    <Icons.User className="no-grow mr10 item-icon" />
  ),
  [LogisticsFilterType.Type]: (
    <Icons.GeneralForm className="no-grow mr10 item-icon" />
  ),
}

const locationFilterTypes: string[] = [
  LogisticsFilterType.Location,
  LogisticsFilterType.Equipment,
]

@inject('eventsStore')
@observer
export default class CompactLogisticsFilter extends React.Component<IProps> {
  @observable private isOptionsShown: boolean = false
  public constructor(props: IProps) {
    super(props)

    Object.keys(this.storesMap).forEach(filterType => {
      const store: BaseLogisticsFilterStore = this.storesMap[filterType]
      store?.clickOnDeselectAll()
      store?.clickOnApply()
    })
  }

  public render() {
    const { isShown, onClose } = this.props
    return (
      <>
        <div
          onClick={onClose}
          className={classList({
            dimmer: true,
            shown: isShown,
          })}
        />
        <div
          className={classList({
            'col filter-holder bb-light-grey': true,
            shown: isShown,
          })}
        >
          {this.renderHeader()}
          {this.renderResetButton()}
          <div className="scrollable">{this.renderContent()}</div>
        </div>
      </>
    )
  }

  private renderResetButton() {
    const { selectedOptionsCount } = this.props

    if (!selectedOptionsCount || this.isOptionsShown) {
      return
    }

    return (
      <div
        className="filter-wrapper no-grow bb-light-grey bt-light-grey filter-handle-button-holder col y-center pl10 text large blue-highlight"
        onClick={this.resetFilters}
      >
        {clearXFilters.replace('{x}', selectedOptionsCount.toString())}
      </div>
    )
  }

  private renderHeader() {
    const { selectedOptionsCount, onClose } = this.props
    return (
      <div className="row pa10">
        <div className="col no-grow">
          {this.isOptionsShown ? (
            <Icon
              icon={IconNames.CHEVRON_LEFT}
              onClick={this.handleBackClick}
            />
          ) : (
            <Icons.Cross
              className="cross-icon no-grow row y-center"
              onClick={onClose}
            />
          )}
        </div>
        <div className="col x-center text large bold">{this.headerLabel}</div>
        <div
          className="col x-center text large bold no-grow compact-select-button px10 py5 brada4 mw-fit-content"
          onClick={this.applyAllFilters}
        >
          {`${Localization.translator.select} ${selectedOptionsCount || ''}`}
        </div>
      </div>
    )
  }

  private get headerLabel(): string {
    let headerLabel = Localization.translator.filters
    Object.keys(this.storesMap).find(filterType => {
      const store = this.storesMap[filterType]
      if (store?.isShown) {
        headerLabel = `${
          Localization.translator.filterBy
        } ${getLogisticsFilterTypeCaption(filterType as LogisticsFilterType)}`
        return
      }
    })

    return headerLabel
  }

  private renderContent() {
    return Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      const shouldRenderPopups = this.isOptionsShown && store?.isShown
      const selectedOptionsCount = store?.selectedOptions?.size

      return (
        <React.Fragment key={filterType}>
          {!this.isOptionsShown && (
            <SuperFilterHandleButton
              filterStore={store}
              onToggle={this.onToggle.bind(this, store)}
              className="no-grow bb-light-grey filter-handle-button-holder col y-center pr10"
            >
              {STORE_TO_ICON_MAP[filterType]}
              <span
                className={classList({
                  'no-grow text extra-large bold capitalize': true,
                  'w-fit-content mr-auto': !selectedOptionsCount,
                })}
              >
                {getLogisticsFilterTypeCaption(
                  filterType as LogisticsFilterType,
                )}
              </span>
              {!!selectedOptionsCount && (
                <div className="ml10 no-grow text extra-large compact-select-button px5 brada4 w-fit-content mr-auto">
                  {selectedOptionsCount}
                </div>
              )}
              <Icon icon={IconNames.CHEVRON_RIGHT} iconSize={16} />
            </SuperFilterHandleButton>
          )}
          {shouldRenderPopups && this.renderPopups(store, filterType)}
        </React.Fragment>
      )
    })
  }

  private renderPopups(store: BaseLogisticsFilterStore, filterType: string) {
    return (
      <CompactSuperFilter
        filterStores={[store]}
        storeToIconMap={STORE_TO_ICON_MAP}
        getFilteredOptionsCount={this.getFilteredOptionsCount}
        shouldRenderHeader={false}
        customIconRender={this.customIconRender.bind(this, filterType)}
        optionFormatter={
          locationFilterTypes.includes(filterType) && this.formatLocation
        }
      />
    )
  }

  private formatLocation = (option: string): JSX.Element => {
    const splitOption = option.split('|')
    if (splitOption.length !== 2) {
      return <>{option}</>
    }
    const tag = this.props.tagsStore.getTag(splitOption[0], splitOption[1])

    if (!tag) {
      return <>{option}</>
    }

    return (
      <div className="row no-flex-children my2 text large">
        <span key={tag.id + tag.type} className="mr4 no-grow tag-cell">
          <SitemapAttributeTag
            dataObject={tag as LocationAttributeBase}
            contentContainerClassName="text-ellipsis py2"
            shouldShowAsTag={true}
          >
            <span>{tag.name}</span>
          </SitemapAttributeTag>
        </span>
      </div>
    )
  }

  private applyAllFilters = () => {
    if (this.isOptionsShown) {
      this.handleBackClick()
    }
    this.props.onClose()
  }

  private customIconRender(filterType: LogisticsFilterType, option: string) {
    switch (filterType) {
      case LogisticsFilterType.App:
        return this.appIcon(option)

      case LogisticsFilterType.ResponsibleContact:
        return this.avatarIcon(option)

      case LogisticsFilterType.Company:
        return this.companyIcon(option)

      case LogisticsFilterType.Type:
        return this.permitIcon(option)

      default:
        return null
    }
  }

  private permitIcon(option: string) {
    return (
      <PermitTypeIcon
        permitType={option}
        className="no-grow mr8 mt4 item-icon"
      />
    )
  }

  private avatarIcon(option: string) {
    const user = this.props.projectMembersStore.getById(option)
    return (
      <Avatar
        src={user?.avatarUrl}
        initials={user?.initials || UNKNOWN_USER_INITIALS}
        size={AvatarSize.Tiny}
        className="mr8"
      />
    )
  }

  private companyIcon(option: string) {
    const company = this.props.companiesStore.getCompanyById(option)
    return (
      <Avatar
        src={company?.avatarUrl}
        avatarType={AvatarType.Company}
        size={AvatarSize.Tiny}
        className="mr8"
      />
    )
  }

  private appIcon(option: string) {
    switch (option) {
      case LogisticItemApp.SCHEDULE:
        return <Icons.GanttSharp className="no-grow mr8 mt4 item-icon" />
      case LogisticItemApp.DELIVERY:
        return <Icons.DeliveryFilled className="no-grow mr8 item-icon" />
      case LogisticItemApp.ANNOUNCEMENT:
        return <Icons.MegaphoneStencil className="no-grow mr8 mt4 item-icon" />
      case LogisticItemApp.FORM:
        return <Icons.FormFilled className="row no-grow mr8 item-icon" />
    }
  }

  private resetFilters = () => {
    Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      store?.clickOnDeselectAll()
      store?.clickOnApply()
    })

    this.props.onClose()
    this.props.onApply && this.props.onApply()
  }

  private handleBackClick = () => {
    Object.keys(this.storesMap).map(filterType => {
      const store = this.storesMap[filterType]
      store?.clickOnApply()
    })

    this.props.onApply && this.props.onApply()
    this.onToggle()
  }

  private onToggle = (store?: BaseLogisticsFilterStore) => {
    this.isOptionsShown = !this.isOptionsShown
    store?.toggle()
  }

  private get storesMap() {
    return this.props.store.filterStoresByTypeMap
  }

  private getFilteredOptionsCount = (itemIds: string[]): number => {
    return itemIds.length
  }
}
